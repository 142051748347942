<template>
  <div>
    <el-table
      :data="signatureSheetsData"
      :style="scroll ? 'max-height: 350px; overflow-y: scroll' : ''"
    >
      <el-table-column v-if="!simplified" prop="companyId" label="Entreprise">
        <template v-if="scope.row.company" slot-scope="scope">
          {{ scope.row.company.name }}
        </template>
      </el-table-column>
      <el-table-column
        prop="session.sessionsBatchId"
        label="Lot"
        align="center"
      >
        <template
          v-if="scope.row.session && scope.row.session.sessionsBatch"
          slot-scope="scope"
        >
          <ColorChip
            v-if="scope.row.session.sessionsBatch.color"
            :color="scope.row.session.sessionsBatch.color"
            display="inline-block"
            class="mr-05"
          />
          {{ scope.row.session.sessionsBatch.label }}
        </template>
      </el-table-column>
      <el-table-column
        prop="signatureSheetTemplateId"
        label="Modèle"
        align="center"
      >
        <template v-if="scope.row.signatureSheetTemplate" slot-scope="scope">
          {{ scope.row.signatureSheetTemplate.label }}
        </template>
      </el-table-column>
      <el-table-column prop="session.scheduledAt" label="Séance" align="center">
        <template v-if="scope.row.session" slot-scope="scope">
          <span v-if="scope.row.session.scheduledAt">{{
            formatDate(scope.row.session.scheduledAt)
          }}</span>
          <span v-else class="text-muted">Non programmée</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="!simplified"
        prop="session.orderId"
        label="Commande"
        align="center"
      >
        <template
          v-if="scope.row.session && scope.row.session.order"
          slot-scope="scope"
        >
          <div v-if="scope.row.session.order.label" class="text-center">
            {{ scope.row.session.order.label }}
          </div>
          <button
            type="button"
            class="btn btn-link p-0"
            @click="goToOrder(scope.row.session.orderId)"
          >
            {{ scope.row.session.order.session }} séance{{
              scope.row.session.order.session > 1 ? "s" : ""
            }}
          </button>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Statut" align="center">
        <template slot-scope="scope">
          <SignatureSheetStatusBadge :status="scope.row.status" />
        </template>
      </el-table-column>
      <el-table-column
        v-if="!simplified"
        prop="submittedAt"
        label="Soumise le"
        align="center"
      >
        <template slot-scope="scope">
          {{ formatDate(scope.row.submittedAt) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="!simplified"
        prop="submittedByCoachEmail"
        label="Soumise par"
        align="center"
      >
        <template v-if="scope.row.submittedBy" slot-scope="scope">
          <button
            type="button"
            class="btn btn-link p-0"
            @click="goToCoach(scope.row.submittedBy.id)"
          >
            <i class="fa fa-user"></i> {{ scope.row.submittedBy.coach.name }}
          </button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="!simplified"
        prop="updatedAt"
        label="Dernière modification"
        align="center"
      >
        <template slot-scope="scope">
          {{
            scope.row.updatedAt
              ? formatDate(scope.row.updatedAt)
              : formatDate(scope.row.createdAt)
          }}
        </template>
      </el-table-column>
      <el-table-column label="Actions" align="right">
        <template slot-scope="scope">
          <button
            class="btn btn-sm btn-outline-secondary mr-05"
            @click="openSessionSignatureSheetModal(scope.row)"
          >
            <i class="fa fa-eye"></i>
          </button>
          <SignatureSheetDownloadButton :signature-sheet="scope.row" />
        </template>
      </el-table-column>
    </el-table>

    <SessionSignatureSheetModal
      :session="signatureSheetDetails"
      @updated="signatureSheetUpdated"
      @close="signatureSheetDetails = null"
    />
  </div>
</template>

<script>
import SignatureSheetDownloadButton from "./SignatureSheetDownloadButton";
import SignatureSheetStatusBadge from "./SignatureSheetStatusBadge";
import SessionSignatureSheetModal from "./SessionSignatureSheetModal";
import ColorChip from "../ColorChip";

export default {
  components: {
    SignatureSheetStatusBadge,
    SignatureSheetDownloadButton,
    SessionSignatureSheetModal,
    ColorChip,
  },

  props: {
    signatureSheets: {
      type: Array,
      default: () => [],
    },

    simplified: {
      type: Boolean,
      default: false,
    },

    scroll: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      signatureSheetsData: [],
      signatureSheetDetails: null,
    };
  },

  watch: {
    signatureSheets: {
      handler() {
        if (this.signatureSheets) {
          this.signatureSheetsData = [...this.signatureSheets];
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.signatureSheetsData = [...this.signatureSheets];
  },

  methods: {
    openSessionSignatureSheetModal(signatureSheet) {
      this.signatureSheetDetails = {
        ...signatureSheet.session,
        signatureSheet,
      };
    },

    signatureSheetUpdated({ sessionId, signatureSheetData }) {
      this.signatureSheetsData = this.signatureSheetsData.map((sheet) => {
        if (sheet.sessionId === sessionId) {
          return {
            ...sheet,
            ...signatureSheetData,
          };
        }

        return sheet;
      });

      this.$emit("updated", signatureSheetData);
    },

    goToOrder(orderId) {
      const route = this.$router.resolve({
        name: "order-details",
        params: { id: orderId },
      });

      window.open(route.href, "_blank");
    },

    goToCoach(id) {
      const route = this.$router.resolve({
        name: "user-details",
        params: { id },
      });

      window.open(route.href, "_blank");
    },
  },
};
</script>
