<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>

    <div class="card-block">
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Entreprise</label>
          <v-select
            v-model="filters.companyId"
            :options="companiesOptions"
            :reduce="({ value }) => value"
            @search="searchCompanies"
            @input="selectCompany"
          ></v-select>
        </div>
        <div class="col-sm-4 form-group">
          <label>Lot</label>
          <v-select
            v-model="filters.sessionsBatchId"
            :options="sessionsBatchesOptions"
            :reduce="({ value }) => value"
            @search="searchSessionsBatches"
            @input="selectSessionBatch"
          ></v-select>
        </div>
        <div class="col-sm-4 form-group">
          <label>Modèle</label>
          <v-select
            v-model="filters.signatureSheetTemplateId"
            :options="signatureSheetTemplatesOptions"
            :reduce="({ value }) => value"
            @search="searchSignatureSheetTemplates"
          ></v-select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 form-group">
          <label>Statut</label>
          <multiselect
            v-model="filters.status"
            :options="signatureSheetStatus"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect"
            @select="statusChange"
            @remove="statusChange"
          />
        </div>
        <div class="col-sm-6 form-group">
          <label>Coach</label>
          <v-select
            v-model="filters.submittedByCoachEmail"
            :options="coachsOptions"
            :reduce="({ value }) => value"
            @search="searchCoachs"
          ></v-select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3 form-group">
          <label>Créée après le</label>
          <datepicker
            v-model="filters.createdAtBegin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>

        <div class="col-sm-3 form-group">
          <label>Créée avant le</label>
          <datepicker
            v-model="filters.createdAtEnd"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>

        <div class="col-sm-3 form-group">
          <label>Mise à jour après le</label>
          <datepicker
            v-model="filters.updatedAtBegin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>

        <div class="col-sm-3 form-group">
          <label>Mise à jour avant le</label>
          <datepicker
            v-model="filters.updatedAtEnd"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3 form-group">
          <label>Séance planifiée après le</label>
          <datepicker
            v-model="filters.scheduledAtBegin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>

        <div class="col-sm-3 form-group">
          <label>Séance planifiée avant le</label>
          <datepicker
            v-model="filters.scheduledAtEnd"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>

        <div class="col-sm-3 form-group">
          <label>Soumise après le</label>
          <datepicker
            v-model="filters.submittedAtBegin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
            @input="submittedAtInput"
          ></datepicker>
        </div>

        <div class="col-sm-3 form-group">
          <label>Soumise avant le</label>
          <datepicker
            v-model="filters.submittedAtEnd"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
            @input="submittedAtInput"
          ></datepicker>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="applyFilters"
      >
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
    </div>
  </div>
</template>

<script>
import debounce from "lodash.debounce";
import { mapGetters } from "vuex";

import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Datepicker,
    Multiselect,
  },

  data() {
    return {
      filters: {
        createdAtBegin: null,
        createdAtEnd: null,
        updatedAtBegin: null,
        updatedAtEnd: null,
        scheduledAtBegin: null,
        scheduledAtEnd: null,
        submittedAtBegin: null,
        submittedAtEnd: null,
        companyId: null,
        sessionsBatchId: null,
        signatureSheetTemplateId: null,
        submittedByCoachEmail: null,
        status: [],
      },
      companiesOptions: [],
      sessionsBatches: [],
      signatureSheetTemplatesOptions: [],
      coachsOptions: [],
    };
  },

  computed: {
    ...mapGetters({
      signatureSheetStatus: "getSignatureSheetStatus",
    }),

    sessionsBatchesOptions() {
      return this.sessionsBatches.map((batch) => ({
        label: batch.label,
        value: batch.id,
      }));
    },
  },

  mounted() {
    this.filters.status = this.signatureSheetStatus.filter(
      (s) => s.value === "pending"
    );
  },

  methods: {
    applyFilters() {
      this.$emit("apply", {
        ...this.filters,
        status: this.filters.status?.map((s) => s.value),
      });
    },

    searchCompanies(search, loading) {
      if (search !== "") {
        this.fetchCompanies(search, loading, this);
      }
    },

    fetchCompanies: debounce(async (search, loading, vm) => {
      if (!search || search.length < 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.post("/companies/search", {
        query: search,
      });

      vm.companiesOptions = data.map((c) => ({
        label: c.name,
        value: c.id,
      }));

      loading(false);

      return vm.companiesOptions;
    }, 300),

    async selectCompany(input) {
      if (input) {
        const { data } = await this.$api.get("/sessions-batches", {
          params: { companyId: input },
        });

        this.sessionsBatches = data;

        this.filters.sessionsBatchId = null;
      }
    },

    searchSessionsBatches(search, loading) {
      if (search !== "") {
        this.fetchSessionsBatches(search, loading, this);
      }
    },

    fetchSessionsBatches: debounce(async (search, loading, vm) => {
      if (!search || search.length < 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.get("/sessions-batches", {
        params: { label: search },
      });

      vm.sessionsBatches = data;

      loading(false);

      return vm.sessionsBatchesOptions;
    }, 300),

    selectSessionBatch(input) {
      if (input) {
        const sessionsBatch = this.sessionsBatches.find(
          (batch) => batch.id === input
        );

        if (sessionsBatch) {
          this.signatureSheetTemplatesOptions =
            sessionsBatch.signatureSheetTemplates.map((c) => ({
              label: c.label,
              value: c.id,
            }));

          this.filters.signatureSheetTemplateId = null;
        }
      }
    },

    searchSignatureSheetTemplates(search, loading) {
      if (search !== "") {
        this.fetchSignatureSheetTemplates(search, loading, this);
      }
    },

    fetchSignatureSheetTemplates: debounce(async (search, loading, vm) => {
      if (!search || search.length < 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.get("/signature-sheets/templates", {
        params: { label: search },
      });

      vm.signatureSheetTemplatesOptions = data.map((c) => ({
        label: c.label,
        value: c.id,
      }));

      loading(false);

      return vm.signatureSheetTemplatesOptions;
    }, 300),

    searchCoachs(search, loading) {
      if (search !== "") {
        this.fetchCoachs(search, loading, this);
      }
    },

    fetchCoachs: debounce(async (search, loading, vm) => {
      if (!search || search.length < 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.post("/users/coachs/search", {
        query: search,
      });

      vm.coachsOptions = data.map((c) => {
        let label = `${c.coach ? `${c.coach.name}` : ""} (${c.email})`;

        return { label, value: c.email };
      });

      loading(false);

      return vm.coachsOptions;
    }, 300),

    submittedAtInput(input) {
      if (input && !this.filters.status.some((s) => s.value === "submitted")) {
        const submittedStatus = this.signatureSheetStatus.find(
          (s) => s.value === "submitted"
        );

        this.filters.status.push(submittedStatus);
      }
    },

    statusChange() {
      if (
        this.filters.status.length === 1 &&
        this.filters.status[0].value === "pending"
      ) {
        this.filters.submittedAtBegin = null;
        this.filters.submittedAtEnd = null;
      }
    },
  },
};
</script>
