<template>
  <div
    :style="`width: ${size}px; min-width: ${size}px; height: ${size}px; min-height: ${size}px; background-color: ${
      color ? color : 'transparent'
    }; border-radius: 50%; overflow: hidden; display: ${display};`"
  ></div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: null,
    },

    size: {
      type: Number,
      default: 15,
    },

    display: {
      type: String,
      default: "block",
    },
  },
};
</script>
