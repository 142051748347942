<template>
  <el-table :data="signatureSheetTemplates">
    <el-table-column prop="label" label="Nom"></el-table-column>
    <el-table-column prop="sessionsBatch.label" label="Lot">
      <template slot-scope="scope">
        <ColorChip
          v-if="scope.row.sessionsBatch.color"
          :color="scope.row.sessionsBatch.color"
          display="inline-block"
          class="mr-05"
        />
        {{ scope.row.sessionsBatch.label }}
      </template>
    </el-table-column>
    <el-table-column
      prop="sessionsBatch.company.name"
      label="Entreprise"
    ></el-table-column>
    <el-table-column prop="createdAt" label="Créé le" align="center">
      <template slot-scope="scope">
        {{ formatDate(scope.row.createdAt) }}
      </template>
    </el-table-column>
    <el-table-column prop="entries" label="Entrées" align="center">
      <template slot-scope="scope">
        {{ scope.row.entries.length }}
      </template>
    </el-table-column>
    <el-table-column prop="signatureSheets" label="Feuilles" align="center">
      <template slot-scope="scope">
        &#8987;:
        {{ getSignatureSheetsStatusCount(scope.row.signatureSheets, "pending")
        }}<br />
        &#10004;:
        {{
          getSignatureSheetsStatusCount(scope.row.signatureSheets, "submitted")
        }}<br />
        &#128233;:
        {{ getSignatureSheetsStatusCount(scope.row.signatureSheets, "sent") }}
      </template>
    </el-table-column>
    <el-table-column prop="updatedAt" label="Modifié le" align="center">
      <template slot-scope="scope">
        {{ scope.row.updatedAt ? formatDate(scope.row.updatedAt) : "-" }}
      </template>
    </el-table-column>
    <el-table-column v-if="userHasWritePermission" label="" align="right">
      <template slot-scope="scope">
        <button
          type="button"
          class="btn btn-sm btn-primary mr-05"
          @click="editTemplate(scope.row)"
        >
          <i class="fa fa-edit"></i>
        </button>
        <button
          type="button"
          class="btn btn-sm btn-danger"
          @click="deleteTemplate(scope.row)"
        >
          <i class="fa fa-trash"></i>
        </button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import ColorChip from "../ColorChip";

export default {
  components: {
    ColorChip,
  },

  props: {
    signatureSheetTemplates: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    userHasWritePermission() {
      return (
        this.hasPermission(this.$store.state.user, "COMPANIES_COMPANY_WRITE") ||
        this.hasPermission(this.$store.state.user, "PUBLIC_PROCUREMENTS_WRITE")
      );
    },
  },

  methods: {
    getSignatureSheetsStatusCount(signatureSheets, status) {
      return signatureSheets.filter(
        (signatureSheet) => signatureSheet.status === status
      ).length;
    },

    editTemplate(template) {
      this.$emit("edit", template);
    },

    deleteTemplate(template) {
      this.$emit("delete", template);
    },
  },
};
</script>
