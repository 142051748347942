<template>
  <div class="wrapper">
    <router-view></router-view>
    <Loader :is-visible="loading" />

    <div
      class="animated fadeIn"
      v-if="['sessions-batches'].indexOf($route.name) > -1"
    >
      <SessionsBatchesFilters @apply="applyFilters" />

      <div class="card">
        <div class="card-header">
          <i class="fa fa-archive"></i> Lots ({{ this.total }})
          <button
            v-if="userHasWritePermission"
            type="button"
            class="btn btn-sm btn-primary float-right"
            @click="modal.show = true"
          >
            <i class="fa fa-plus mr-05"></i> Ajouter
          </button>
        </div>

        <div class="card-block">
          <SessionsBatchesTable
            :sessions-batches="sessionsBatches"
            @edit="editSessionsBatch"
            @deleted="sessionsBatchDeleted"
          />

          <paginate
            ref="paginate"
            v-model="currentPage"
            :page-count="pageCount"
            :click-handler="changePage"
            prev-text="Précédent"
            next-text="Suivant"
            container-class="pagination"
            class="mt-1"
          ></paginate>
        </div>
      </div>
    </div>

    <SessionsBatchModal
      :show="modal.show"
      :sessions-batch="modal.sessionsBatch"
      @added="sessionsBatchAdded"
      @updated="sessionsBatchUpdated"
      @close="closeModal"
    />
  </div>
</template>

<script>
import Loader from "../components/Loader";
import Paginate from "vuejs-paginate";
import SessionsBatchesFilters from "../components/company/SessionsBatchesFilters";
import SessionsBatchesTable from "../components/company/SessionsBatchesTable";
import SessionsBatchModal from "../components/company/SessionsBatchModal";

export default {
  components: {
    Loader,
    SessionsBatchesFilters,
    SessionsBatchesTable,
    SessionsBatchModal,
    Paginate,
  },

  data() {
    return {
      loading: false,
      sessionsBatches: [],
      total: 0,
      currentPage: 1,
      limit: 15,
      filters: {
        createdAtBegin: null,
        createdAtEnd: null,
        label: "",
        companyId: null,
      },
      modal: {
        show: false,
        sessionsBatch: null,
      },
    };
  },

  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },

    pageCount() {
      if (!this.total) {
        return 0;
      }

      return Math.ceil(this.total / this.limit);
    },

    userHasWritePermission() {
      return (
        this.hasPermission(this.$store.state.user, "COMPANIES_COMPANY_WRITE") ||
        this.hasPermission(this.$store.state.user, "PUBLIC_PROCUREMENTS_WRITE")
      );
    },
  },

  watch: {
    currentPage() {
      this.$refs.paginate.selected = this.currentPage - 1;
    },
  },

  mounted() {
    this.fetchSessionsBatches();
  },

  methods: {
    async fetchSessionsBatches() {
      try {
        this.loading = true;

        const { data } = await this.$api.get("/sessions-batches", {
          params: {
            offset: this.offset,
            limit: this.limit,
            ...this.filters,
          },
        });

        this.total = data.total;
        this.sessionsBatches = data.sessionsBatches;
      } catch (e) {
        this.sessionsBatches = [];
      } finally {
        this.loading = false;
      }
    },

    applyFilters(filters) {
      this.filters = { ...this.filters, ...filters };

      this.changePage(1);
    },

    changePage(page) {
      this.currentPage = page;

      this.fetchSessionsBatches();
    },

    closeModal() {
      this.modal.show = false;
      this.modal.sessionsBatch = null;
    },

    editSessionsBatch(sessionsBatch) {
      this.modal.sessionsBatch = sessionsBatch;
      this.modal.show = true;
    },

    sessionsBatchAdded(item) {
      this.sessionsBatches.unshift(item);
    },

    sessionsBatchUpdated(item) {
      this.sessionsBatches = this.sessionsBatches.map((sessionsBatch) =>
        sessionsBatch.id === item.id
          ? { ...sessionsBatch, ...item }
          : sessionsBatch
      );
    },

    sessionsBatchDeleted(item) {
      this.sessionsBatches = this.sessionsBatches.filter(
        (sessionsBatch) => sessionsBatch.id !== item.id
      );
    },
  },
};
</script>
