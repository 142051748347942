<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-people"></i> Fiches clients
      <button
        v-if="userHasWritePermission"
        class="btn btn-sm btn-primary float-right"
        @click="addClientModal.show = true"
      >
        <i class="fa fa-plus"></i> Ajouter
      </button>
    </div>
    <div class="card-block">
      <el-table :data="companyClients">
        <el-table-column label="Nom">
          <template slot-scope="scope">
            <a @click="goToClient(scope.row.id)">
              <span v-if="scope.row.firstName && scope.row.lastName">
                {{ scope.row.firstName }}
                {{ scope.row.lastName }}
              </span>
              <span v-else>{{ scope.row.email }}</span>
            </a>
            <br />
          </template>
        </el-table-column>
        <el-table-column label="Statut" align="center">
          <template slot-scope="scope">
            <StatusRel v-if="scope.row.status" :status="scope.row.status" />
            <br />
            <span v-if="scope.row.status && scope.row.status === 'lost'">
              {{ lostReasonsLabel(scope.row.lostReason) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Facturation" align="center">
          <template slot-scope="scope">
            <el-switch
              v-if="
                hasPermission($store.state.user, 'ACQUISITIONS_CLIENT_WRITE')
              "
              :value="scope.row.isB2BBilling"
              active-text="Oui"
              inactive-text="Non"
              size="mini"
              @change="toggleBilling(scope.row.email)"
            />
            <span v-else>
              {{ scope.row.isB2BBilling ? "Oui" : "Non" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="right" width="100">
          <template slot-scope="scope">
            <div
              v-if="company.referentClientEmail === scope.row.email"
              class="d-flex justify-content-end"
            >
              <i
                class="fa fa-star d-block"
                v-tooltip="{
                  content: 'Client référent de l\'entreprise',
                  placement: 'top',
                }"
              >
              </i>
            </div>
            <button
              v-else-if="userHasWritePermission"
              class="btn btn-danger btn-sm"
              @click="unlinkCompany(scope.row.email)"
              v-tooltip="{
                content: 'Supprimer le client de l\'entreprise',
                placement: 'top',
              }"
            >
              <i class="fa fa-chain-broken"></i>
            </button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <modal
      v-if="userHasWritePermission"
      title="Ajouter une fiche client"
      v-model="addClientModal.show"
      effect="fade/zoom"
    >
      <div slot="modal-body" class="modal-body">
        <multiselect
          v-model="addClientModal.form.client"
          :options="clientsOptions"
          :multiple="false"
          :allow-empty="false"
          label="label"
          track-by="label"
          :searchable="true"
          :loading="clientsIsLoading"
          :options-limit="10"
          :max-height="600"
          :hide-selected="true"
          @search-change="searchClients"
        >
          <template v-slot:noOptions>Rechercher un client</template>
          <template v-slot:noResult>Aucun client trouvé</template>
        </multiselect>
      </div>
      <div
        slot="modal-footer"
        class="modal-footer d-flex justify-content-between"
      >
        <button
          type="button"
          class="btn btn-default mr-1"
          @click="addClientModal.show = false"
        >
          Annuler
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="linkClientCompany()"
        >
          Créer
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import StatusRel from "../StatusRel";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import Modal from "vue-strap/src/Modal";
import debounce from "lodash.debounce";

export default {
  components: {
    StatusRel,
    Multiselect,
    Modal,
  },

  data() {
    return {
      companyClients: [],
      clientsOptions: [],
      addClientModal: {
        show: false,
        form: {
          client: null,
        },
      },
      clientsIsLoading: false,
    };
  },

  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      lostReasons: "getLostReasons",
      companyLost: "getCompanyLost",
    }),

    globalLostReasons() {
      return this.companyLost.concat(this.lostReasons);
    },

    userHasWritePermission() {
      return this.$route.name === "public-procurement-details"
        ? this.hasPermission(
            this.$store.state.user,
            "PUBLIC_PROCUREMENTS_WRITE"
          )
        : this.hasPermission(this.$store.state.user, "COMPANIES_COMPANY_WRITE");
    },
  },

  created() {
    this.companyClients = [...this.company.clients];
  },

  methods: {
    lostReasonsLabel(lostReasonCode) {
      const lostReason = this.globalLostReasons.find(
        (reason) => reason.value === lostReasonCode
      );

      return lostReason ? lostReason.label : null;
    },

    goToClient(clientId) {
      const route = this.$router.resolve({
        name: "client-details",
        params: {
          id: clientId,
        },
      });

      window.open(route.href, "_blank");
    },

    async unlinkCompany(clientEmail) {
      this.$confirm({
        message:
          "Êtes-vous sûr de vouloir supprimer ce client de l'entreprise ?",
        onConfirm: async () => {
          try {
            await this.$api.patch(`/companies/${this.company.id}/unlink`, {
              clientEmail,
            });

            this.companyClients = this.companyClients.filter(
              (client) => client.email !== clientEmail
            );

            this.$emit("unlinked", clientEmail);
          } catch (e) {
            this.$errorToast(
              "Impossible de supprimer le client de l'entreprise"
            );
          }
        },
      });
    },

    async linkClientCompany() {
      if (
        !this.addClientModal.form ||
        !this.addClientModal.form.client?.value
      ) {
        return;
      }

      try {
        await this.$api.patch(`/companies/${this.company.id}/link`, {
          clientEmail: this.addClientModal.form.client.value,
        });

        this.$emit("linked", this.addClientModal.form.client);

        this.companyClients.push(this.addClientModal.form.client);
        this.addClientModal.show = false;
        this.addClientModal.form.client = null;
        this.clientsOptions = [];
      } catch (e) {
        this.$errorToast("Impossible de lier le client à l'entreprise");
      }
    },

    async searchClients(search) {
      if (search !== "") {
        await this.fetchClients(search, this);
      }
    },

    fetchClients: debounce(async (search, vm) => {
      if (!search || !search.length > 3) {
        return;
      }

      vm.clientsIsLoading = true;

      const { data } = await vm.$api.get(`/clients/filter?q=${search}`);

      vm.clientsOptions = data.map((c) => ({
        label: c.name + " (" + c.email + ")",
        value: c.email,
        id: c.id,
        email: c.email,
        firstName: c.firstName,
        lastName: c.lastName,
        company: c.company,
        status: c.status || "prospect",
        lostReason: c.lostReason,
        isB2BBilling: c.isB2BBilling,
      }));

      vm.clientsIsLoading = false;

      return vm.clientsOptions;
    }, 300),

    async toggleBilling(clientEmail) {
      try {
        await this.$api.post("/clients/is-b2b-billing", {
          clientEmail,
        });

        this.companyClients = this.companyClients.map((client) => {
          if (client.email === clientEmail) {
            return {
              ...client,
              isB2BBilling: !client.isB2BBilling,
            };
          }

          return client;
        });
      } catch (e) {
        this.$errorToast("Impossible de modifier le statut de facturation");
      }
    },
  },
};
</script>
<style type="text/css" scoped>
.checkboxes {
  display: flex;
  justify-content: space-between;
  max-width: 600px;
}

.type {
  display: flex;
  align-items: center;
}

.type label {
  margin: 0;
}

.type input {
  height: 14px;
  width: 30px;
}
</style>
