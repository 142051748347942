<template>
  <modal :value="show" large effect="fade/zoom" @cancel="close">
    <Loader :is-visible="loading" />

    <template v-if="session && session.signatureSheet">
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">
          Séance
          {{
            session.scheduledAt
              ? `du ${formatDateTime(session.scheduledAt)}`
              : "non programmée"
          }}
        </h4>
        <div class="text-right">
          <h5 class="text-muted">{{ templateLabel }}</h5>
          <h6 class="text-muted">{{ sessionsBatchLabel }}</h6>
        </div>
      </div>

      <form>
        <div class="form-group">
          <label>Statut</label>
          <template v-if="userHasWritePermission">
            <v-select
              v-model="status"
              :options="signatureSheetStatus"
              :reduce="({ value }) => value"
            ></v-select>
            <small v-if="errors.status" class="text-danger">{{
              errors.status
            }}</small>
          </template>

          <SignatureSheetStatusBadge v-else :status="status" />
        </div>
        <div v-if="status !== 'pending'" class="form-group">
          <label>Coach ayant soumis la feuille</label>
          <template v-if="userHasWritePermission">
            <v-select
              v-model="submittedByCoachEmail"
              :options="coachsOptions"
              :reduce="({ value }) => value"
            />
            <small v-if="errors.submittedByCoachEmail" class="text-danger">{{
              errors.submittedByCoachEmail
            }}</small>
          </template>

          <button
            v-else-if="session.signatureSheet.submittedBy"
            type="button"
            class="btn btn-link"
          >
            {{ session.signatureSheet.submittedBy.coach.name }}
          </button>
        </div>
        <div
          v-if="session.signatureSheet.submittedAt"
          class="form-group badge badge-info"
        >
          Soumise le {{ formatDate(session.signatureSheet.submittedAt) }}
        </div>
        <div class="form-group">
          <label>Champs</label>
          <div class="d-flex flex-wrap">
            <div
              v-for="(field, index) in fields"
              :key="`modal-form-field-${index}`"
              class="col-2"
              style="padding: 0 5px 0 0; margin-bottom: 5px"
            >
              <input
                :value="field"
                type="text"
                class="form-control"
                :disabled="!userHasWritePermission"
                @input="(e) => updateField(index, e.target.value)"
              />
            </div>

            <div
              v-if="userHasWritePermission"
              class="d-flex"
              style="margin-bottom: 5px"
            >
              <button
                v-if="fields.length > 1"
                type="button"
                class="btn btn-sm btn-secondary mr-05"
                @click="fields.pop()"
              >
                <i class="fa fa-minus"></i>
              </button>
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                @click="fields.push('')"
              >
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>

          <small v-if="errors.fields" class="text-danger">{{
            errors.fields
          }}</small>
        </div>

        <div class="form-group">
          <label>Entrées</label>
          <div v-if="fields.length === 0" class="text-muted">
            Veuillez ajouter des champs
          </div>
          <div style="max-height: 300px; overflow-y: auto">
            <div
              v-for="(entry, index) in entries"
              :key="`modal-form-entry-${index}`"
              class="d-flex flex-wrap mb-1"
            >
              <div
                v-for="i in fields.length"
                :key="`modal-form-entry-${index}-entryField-${i - 1}`"
                class="col-2"
                style="padding: 0 5px 0 0; margin-bottom: 5px"
              >
                <input
                  :value="
                    i - 1 === signatureFieldIndex
                      ? !!entry[i - 1]
                        ? '✓'
                        : ''
                      : entry[i - 1]
                  "
                  type="text"
                  class="form-control"
                  :disabled="
                    i - 1 === signatureFieldIndex || !userHasWritePermission
                  "
                  @input="(e) => updateEntryField(index, i - 1, e.target.value)"
                />
              </div>
            </div>
          </div>
          <div
            v-if="fields.length > 0 && userHasWritePermission"
            class="d-flex"
          >
            <button
              v-if="entries.length > 0"
              type="button"
              class="btn btn-sm btn-secondary mr-05"
              @click="entries.pop()"
            >
              <i class="fa fa-minus"></i>
            </button>
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              @click="addEntry"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>

          <small v-if="errors.entries" class="text-danger">{{
            errors.entries
          }}</small>
        </div>
      </form>

      <div slot="modal-footer" class="modal-footer">
        <button type="button" class="btn btn-default" @click="close">
          Fermer
        </button>
        <button
          v-if="userHasWritePermission && isEditing"
          type="button"
          class="btn btn-primary"
          @click="editSignatureSheet"
        >
          Modifier
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";

import Modal from "vue-strap/src/Modal";
import Loader from "../Loader";
import SignatureSheetStatusBadge from "./SignatureSheetStatusBadge";

export default {
  components: {
    Modal,
    Loader,
    SignatureSheetStatusBadge,
  },

  props: {
    session: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      status: null,
      submittedByCoachEmail: null,
      fields: [],
      entries: [],
      errors: {
        status: null,
        submittedByCoachEmail: null,
        fields: null,
        entries: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      signatureSheetStatus: "getSignatureSheetStatus",
    }),

    show() {
      return !!this.session?.signatureSheet;
    },

    userHasWritePermission() {
      return (
        this.hasPermission(this.$store.state.user, "COMPANIES_COMPANY_WRITE") ||
        this.hasPermission(this.$store.state.user, "PUBLIC_PROCUREMENTS_WRITE")
      );
    },

    templateLabel() {
      return (
        this.session?.signatureSheet?.signatureSheetTemplate?.label ||
        "Feuille d'émargement"
      );
    },

    sessionsBatchLabel() {
      return (
        this.session?.sessionsBatch?.label ||
        this.session?.signatureSheet?.signatureSheetTemplate?.sessionsBatch
          ?.label ||
        ""
      );
    },

    isEditing() {
      if (!this.session?.signatureSheet) {
        return false;
      }

      return (
        this.status !== this.session.signatureSheet.status ||
        this.submittedByCoachEmail !==
          this.session.signatureSheet.submittedByCoachEmail ||
        this.fields.toString() !==
          this.session.signatureSheet.fields.toString() ||
        this.entries.join(";") !== this.session.signatureSheet.entries.join(";")
      );
    },

    coachsOptions() {
      return (
        this.session?.coachs.map((coach) => ({
          label: coach.coach.name,
          value: coach.email,
        })) || []
      );
    },

    signatureFieldIndex() {
      const signatureField = this.fields.find((field) =>
        field.match(/signature/i)
      );

      return this.fields.indexOf(signatureField);
    },
  },

  watch: {
    session() {
      if (this.session?.signatureSheet) {
        const { status, submittedByCoachEmail, fields, entries } =
          this.session.signatureSheet;

        this.status = status;
        this.submittedByCoachEmail = submittedByCoachEmail;
        this.fields = [...fields];
        this.entries = [...entries];
      } else {
        this.status = null;
        this.submittedByCoachEmail = null;
        this.fields = [];
        this.entries = [];
      }
    },
  },

  mounted() {
    if (this.session?.signatureSheet) {
      const { status, submittedByCoachEmail, fields, entries } =
        this.session.signatureSheet;

      this.status = status;
      this.submittedByCoachEmail = submittedByCoachEmail;
      this.fields = [...fields];
      this.entries = [...entries];
    } else {
      this.status = null;
      this.submittedByCoachEmail = null;
      this.fields = [];
      this.entries = [];
    }
  },

  methods: {
    close() {
      this.$emit("close");
    },

    updateField(fieldIndex, value) {
      this.fields = this.fields.map((field, index) =>
        index === fieldIndex ? value : field
      );
    },

    updateEntryField(entryIndex, entryFieldIndex, value) {
      this.entries = this.entries.map((entry, index) => {
        if (index === entryIndex) {
          return entry.map((field, i) => {
            if (i === entryFieldIndex) {
              return value;
            }

            return field;
          });
        }

        return entry;
      });
    },

    addEntry() {
      this.entries.push(this.fields.map(() => ""));
    },

    clearErrors() {
      this.errors = {
        status: null,
        submittedByCoachEmail: null,
        fields: null,
        entries: null,
      };
    },

    checkFormErrors() {
      if (!this.status) {
        this.errors.status = "Veuillez sélectionner un statut";
      }

      if (this.status !== "pending" && !this.submittedByCoachEmail) {
        this.errors.submittedByCoachEmail = "Veuillez sélectionner un coach";
      }

      if (this.fields.length === 0) {
        this.errors.fields = "Veuillez ajouter des champs";
      } else if (this.fields.some((field) => !field || !field.trim())) {
        this.errors.fields = "Un ou plusieurs champs sont vides";
      }

      if (this.entries.some((entry) => entry.every((field) => !field))) {
        this.errors.entries = "Une ou plusieurs entrées sont vides";
      }
    },

    async editSignatureSheet() {
      try {
        if (!this.session?.signatureSheet) {
          return;
        }

        if (
          this.session.signatureSheet.status === this.status &&
          this.session.signatureSheet.submittedByCoachEmail ===
            this.submittedByCoachEmail &&
          this.session.signatureSheet.fields.toString() ===
            this.fields.toString() &&
          this.session.signatureSheet.entries.join(";") ===
            this.entries.join(";")
        ) {
          this.close();

          return;
        }

        this.clearErrors();

        this.checkFormErrors();

        if (Object.values(this.errors).some((error) => !!error)) {
          return;
        }

        this.loading = true;

        const { data } = await this.$api.patch(
          `/signature-sheets/${this.session.signatureSheet.id}`,
          {
            status: this.status,
            submittedByCoachEmail: this.submittedByCoachEmail,
            fields: this.fields,
            entries: this.entries,
          }
        );

        this.$emit("updated", {
          sessionId: this.session.id,
          signatureSheetData: data,
        });

        this.$successToast("La feuille d'émargement a bien été modifiée");

        this.close();
      } catch (e) {
        this.$errorToast("Impossible de  modifier la  feuille d'émargement");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
