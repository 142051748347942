<template>
  <div v-if="displayCard" class="card">
    <Loader :is-visible="loading" />

    <div class="card-header"><i class="fa fa-pencil"></i> Émargement</div>

    <div class="card-block">
      <el-switch
        :value="featureEnabled"
        active-text="Activé"
        inactive-text="Désactivé"
        @change="toggleFeatureEnabled"
      ></el-switch>

      <div v-if="featureEnabled" class="mt-1">
        <dl class="row">
          <dt class="col-sm-3">Entreprise</dt>
          <dd class="col-sm-9">{{ company.name }}</dd>

          <dt class="col-sm-3">Lot</dt>
          <dd class="col-sm-9">
            <template v-if="editing">
              <v-select
                v-model="form.sessionsBatchId"
                :options="sessionsBatchesOptions"
                :reduce="({ value }) => value"
                @input="selectSessionsBatch"
              >
                <template #no-options>
                  Créez un lot pour cette entreprise depuis le menu B2B >
                  Émargement > Lots
                </template></v-select
              >
              <small v-if="formErrors.sessionsBatchId" class="text-danger">{{
                formErrors.sessionsBatchId
              }}</small>
            </template>
            <span v-else-if="sessionsBatch">{{ sessionsBatch.label }}</span>
          </dd>

          <dt class="col-sm-3">Modèle</dt>
          <dd class="col-sm-9">
            <template v-if="editing">
              <v-select
                v-model="form.signatureSheetTemplateId"
                :options="signatureSheetTemplatesOptions"
                :reduce="({ value }) => value"
              >
                <template #no-options>
                  {{
                    form.sessionsBatchId
                      ? "Créez un modèle de feuille d'émargement pour cet lot depuis le menu B2B > Émargement > Modèles"
                      : "Veuillez sélectionner un lot"
                  }}
                </template></v-select
              >
              <small
                v-if="formErrors.signatureSheetTemplateId"
                class="text-danger"
                >{{ formErrors.signatureSheetTemplateId }}</small
              >
            </template>
            <span v-else-if="signatureSheetTemplate">{{
              signatureSheetTemplate.label
            }}</span>
          </dd>
        </dl>

        <button
          v-if="!editing"
          type="button"
          class="btn btn-sm btn-primary"
          @click="editing = true"
        >
          <i class="fa fa-edit"></i> Modifier
        </button>

        <template v-else>
          <div class="alert alert-info">
            {{
              sessionsBatchId || signatureSheetTemplateId
                ? `Toute modification entraînera la modification des feuilles d'émargement reliées au séances de la commande et dont le statut est "En attente".`
                : "Une feuille d'émargement sera créée pour chaque séance de la commande à partir du modèle sélectionné."
            }}
          </div>

          <div class="d-flex">
            <button
              v-if="sessionsBatchId || signatureSheetTemplateId"
              type="button"
              class="btn btn-sm btn-secondary mr-05"
              @click="cancelEditing"
            >
              <i class="fa fa-times"></i> Annuler
            </button>
            <button
              type="button"
              class="btn btn-sm btn-primary"
              @click="confirmEdit"
            >
              <i class="fa fa-check"></i>
              {{
                sessionsBatchId || signatureSheetTemplateId
                  ? "Confirmer"
                  : "Ajouter"
              }}
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "./Loader";

export default {
  components: {
    Loader,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },

    client: {
      type: Object,
      required: true,
    },

    sessions: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      featureEnabled: false,
      loading: false,
      editing: false,
      company: null,
      sessionsBatchId: null,
      signatureSheetTemplateId: null,
      form: {
        sessionsBatchId: null,
        signatureSheetTemplateId: null,
      },
      formErrors: {
        sessionsBatchId: null,
        signatureSheetTemplateId: null,
      },
    };
  },

  computed: {
    displayCard() {
      if (!this.company) {
        return false;
      }

      return (
        this.company.features?.some(
          (feat) => feat.featureName === "signatureSheets"
        ) ||
        (!!this.sessionsBatchId && !!this.signatureSheetTemplateId)
      );
    },

    sessionsBatch() {
      if (!this.sessionsBatchId || !this.company) {
        return null;
      }

      const batch = this.company.sessionsBatches.find(
        (b) => b.id === this.sessionsBatchId
      );

      return batch ? { ...batch, value: batch.id } : null;
    },

    signatureSheetTemplate() {
      if (!this.sessionsBatch || !this.signatureSheetTemplateId) {
        return null;
      }

      const template = this.sessionsBatch.signatureSheetTemplates.find(
        (t) => t.id === this.signatureSheetTemplateId
      );

      return template ? { ...template, value: template.id } : null;
    },

    sessionsBatchesOptions() {
      if (!this.company) {
        return [];
      }

      return this.company.sessionsBatches.map((batch) => ({
        value: batch.id,
        label: batch.label,
      }));
    },

    signatureSheetTemplatesOptions() {
      if (!this.form.sessionsBatchId) {
        return [];
      }

      const sessionsBatch = this.company.sessionsBatches.find(
        (batch) => batch.id === this.form.sessionsBatchId
      );

      if (!sessionsBatch) {
        return [];
      }

      return sessionsBatch.signatureSheetTemplates.map((template) => ({
        value: template.id,
        label: template.label,
      }));
    },
  },

  mounted() {
    this.sessionsBatchId = this.order.sessionsBatchId;
    this.signatureSheetTemplateId = this.order.signatureSheetTemplateId;
    this.form.sessionsBatchId = this.sessionsBatchId;
    this.form.signatureSheetTemplateId = this.signatureSheetTemplateId;
    this.editing = !this.sessionsBatchId && !this.signatureSheetTemplateId;
    this.featureEnabled =
      !!this.sessionsBatchId && !!this.signatureSheetTemplateId;

    this.getCompany();
  },

  methods: {
    async getCompany() {
      if (!this.client.companyId) {
        return;
      }

      try {
        const { data } = await this.$api.get(
          `/companies/${this.client.companyId}`
        );

        this.company = data;
      } catch (e) {
        this.company = null;
      }
    },

    cancelEditing() {
      this.editing = false;

      this.resetForm();
    },

    resetForm() {
      this.form.sessionsBatchId = this.sessionsBatchId;
      this.form.signatureSheetTemplateId = this.signatureSheetTemplateId;

      this.clearFormErrors();
    },

    clearFormErrors() {
      this.formErrors = {
        sessionsBatchId: null,
        signatureSheetTemplateId: null,
      };
    },

    checkFormErrors() {
      this.clearFormErrors();

      if (!this.form.sessionsBatchId) {
        this.formErrors.sessionsBatchId = "Veuillez sélectionner un lot";
      }

      if (!this.form.signatureSheetTemplateId) {
        this.formErrors.signatureSheetTemplateId =
          "Veuillez sélectionner un modèle";
      }

      return !Object.values(this.formErrors).some((error) => error);
    },

    async confirmEdit() {
      const isValid = this.checkFormErrors();

      if (!isValid) {
        return;
      }

      if (
        this.form.sessionsBatchId === this.sessionsBatchId &&
        this.form.signatureSheetTemplateId === this.signatureSheetTemplateId
      ) {
        this.editing = false;

        return;
      }

      try {
        this.loading = true;

        await this.$api.patch(
          `/orders/${this.order.id}/signature-sheet-template`,
          this.form
        );

        this.$successToast("Informations d'émargement modifiées avec succès");

        this.sessionsBatchId = this.form.sessionsBatchId;
        this.signatureSheetTemplateId = this.form.signatureSheetTemplateId;
        this.editing = false;

        this.$emit("reload");
      } catch (e) {
        this.$errorToast(
          "Impossible de modifier les informations d'émargement"
        );
      } finally {
        this.loading = false;
      }
    },

    toggleFeatureEnabled(enabled) {
      if (!enabled && this.sessionsBatchId && this.signatureSheetTemplateId) {
        this.$confirm({
          message:
            "Êtes-vous sûr de vouloir désactiver la fonctionnalité d'émargement pour cette commande ? Cela supprimera toutes les feuilles d'émargement associées aux séances qui sont encore \"En attente\".",
          onConfirm: async () => {
            try {
              this.loading = true;

              await this.$api.patch(
                `/orders/${this.order.id}/signature-sheet-template`,
                {
                  sessionsBatchId: null,
                  signatureSheetTemplateId: null,
                }
              );

              this.$successToast(
                "Fonctionnalité d'émargement désactivée avec succès"
              );

              this.sessionsBatchId = null;
              this.signatureSheetTemplateId = null;
              this.featureEnabled = false;

              this.$emit("reload");
            } catch (e) {
              this.$errorToast(
                "Impossible de désactiver la fonctionnalité d'émargement"
              );
            } finally {
              this.loading = false;
            }
          },
        });
      } else {
        this.featureEnabled = enabled;
        this.editing = enabled;
      }
    },

    selectSessionsBatch(value) {
      if (!value) {
        this.form.signatureSheetTemplateId = null;
      }
    },
  },
};
</script>
