<template>
  <modal
    v-if="userHasWritePermission"
    :title="form.id ? 'Éditer le lot' : 'Ajouter un lot'"
    :value="show"
    effect="fade/zoom"
    cancelText="Fermer"
    :okText="form.id ? 'Modifier' : 'Créer'"
    @ok="createOrUpdateSessionsBatch"
    @cancel="closeModal"
  >
    <Loader :is-visible="loading" />

    <div class="modal-body">
      <form>
        <div class="form-group">
          <label>Nom</label>
          <el-input type="text" v-model="form.label" placeholder="Nom du lot" />
        </div>

        <div class="form-group">
          <label>Entreprise</label>
          <v-select
            v-if="!form.id"
            v-model="form.company"
            :options="companiesOptions"
            :disabled="!!company"
            @search="searchCompanies"
          ></v-select>
          <div v-else class="font-weight-bold">{{ form.company.label }}</div>
        </div>

        <div class="form-group">
          <label>Couleur</label>
          <el-color-picker v-model="form.color" class="d-block" />
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import debounce from "lodash.debounce";
import Modal from "vue-strap/src/Modal";
import Loader from "../Loader";

export default {
  components: {
    Modal,
    Loader,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    sessionsBatch: {
      type: Object,
      default: null,
    },

    company: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      form: {
        id: null,
        label: "",
        company: null,
        color: null,
      },
      errors: {
        label: null,
        company: null,
      },
      companiesOptions: [],
    };
  },

  computed: {
    userHasWritePermission() {
      return (
        this.hasPermission(this.$store.state.user, "COMPANIES_COMPANY_WRITE") ||
        this.hasPermission(this.$store.state.user, "PUBLIC_PROCUREMENTS_WRITE")
      );
    },
  },

  watch: {
    sessionsBatch() {
      if (this.sessionsBatch) {
        this.form = {
          id: this.sessionsBatch.id,
          label: this.sessionsBatch.label,
          company: {
            label: this.sessionsBatch.company.name,
            value: this.sessionsBatch.company.id,
          },
          color: this.sessionsBatch.color,
        };
      } else {
        this.resetForm();
      }
    },

    company: {
      handler() {
        if (this.company) {
          this.companiesOptions = [
            {
              label: this.company.name,
              value: this.company.id,
            },
          ];

          this.form.company = this.companiesOptions[0];
        }
      },
      deep: true,
    },
  },

  mounted() {
    if (this.company) {
      this.companiesOptions = [
        {
          label: this.company.name,
          value: this.company.id,
        },
      ];

      this.form.company = this.companiesOptions[0];
    }
  },

  methods: {
    resetForm() {
      this.form = {
        id: null,
        label: "",
        company: null,
        color: null,
      };

      this.companiesOptions = [];

      this.clearErrors();
    },

    clearErrors() {
      this.errors = {
        label: null,
        company: null,
      };
    },

    closeModal() {
      this.$emit("close");

      this.resetForm();
    },

    searchCompanies(search, loading) {
      if (this.company) {
        return;
      }

      if (search !== "") {
        this.fetchCompanies(search, loading, this);
      }
    },

    fetchCompanies: debounce(async (search, loading, vm) => {
      if (!search || search.length < 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.post("/companies/search", {
        query: search,
      });

      vm.companiesOptions = data.map(({ id, name }) => ({
        label: name,
        value: id,
      }));

      loading(false);

      return vm.clients;
    }, 300),

    async createOrUpdateSessionsBatch() {
      try {
        this.clearErrors();

        this.loading = true;

        if (!this.form.label || !this.form.label.trim()) {
          this.errors.label = "Le nom du lot est requis";

          return;
        }

        if (!this.form.company) {
          this.errors.company = "Veuillez choisir une entreprise";

          return;
        }

        if (this.form.id) {
          const { data } = await this.$api.patch(
            `/sessions-batches/${this.form.id}`,
            {
              label: this.form.label.trim(),
              color: this.form.color,
            }
          );

          this.$emit("updated", data);
        } else {
          const { data } = await this.$api.post("/sessions-batches", {
            label: this.form.label.trim(),
            companyId: this.form.company.value,
            color: this.form.color,
          });

          this.$emit("added", {
            ...data,
            company: {
              id: this.form.company.value,
              name: this.form.company.label,
            },
            sessions: [],
            signatureSheetTemplates: [],
          });
        }

        this.$successToast(
          `Le lot a bien été ${this.form.id ? "modifié" : "créé"}`
        );

        this.closeModal();
      } catch (e) {
        this.$errorToast(
          `Impossible de ${this.form.id ? "modifier" : "créer"} le lot`
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
