<template>
  <div
    v-if="statusDetails"
    class="badge"
    :style="`background-color: ${statusDetails.color}`"
  >
    {{ statusDetails.label }}
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      signatureSheetStatus: "getSignatureSheetStatus",
    }),

    statusDetails() {
      let details = this.signatureSheetStatus.find(
        (s) => s.value === this.status
      );

      if (!details) {
        details = this.signatureSheetStatus.find((s) => s.value === "pending");
      }

      return details;
    },
  },
};
</script>
