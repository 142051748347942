<template>
  <div class="marketing-custom-wrapper">
    <el-table class="" :data="form">
      <el-table-column label="createdAt" prop="createdAt" width="100">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createdAt) }}
        </template>
      </el-table-column>

      <el-table-column
        label="utm_source"
        prop="utm_source"
        :width="width"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.utm_source }}
        </template>
      </el-table-column>

      <el-table-column
        label="utm_medium"
        prop="utm_medium"
        :width="width"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.utm_medium }}
        </template>
      </el-table-column>

      <el-table-column
        label="utm_term"
        prop="utm_term"
        :width="width"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.utm_term }}
        </template>
      </el-table-column>

      <el-table-column
        label="utm_content"
        prop="utm_content"
        :width="width"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.utm_content }}
        </template>
      </el-table-column>

      <el-table-column
        label="utm_campaign"
        prop="utm_campaign"
        :width="width"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.utm_campaign }}
        </template></el-table-column
      >

      <el-table-column
        label="gclid"
        prop="gclid"
        :width="width"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.gclid }}
        </template>
      </el-table-column>

      <el-table-column
        label="referer"
        prop="referer"
        :width="width"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.referer }}
        </template>
      </el-table-column>

      <el-table-column
        label="sourceUrl"
        prop="sourceUrl"
        :width="width"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.sourceUrl }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    marketings: {
      type: Array,
      required: true,
    },
  },

  data() {
    return { form: {}, width: 200 };
  },

  created() {
    this.form = this.marketings || [];
  },

  watch: {
    marketings: {
      handler: function (val) {
        this.form = val;
      },
      deep: true,
    },
  },

  methods: {},
};
</script>

<style lang="scss">
/*[TODO] May we improved */
.marketing-custom-wrapper {
  max-width: calc(100vw - 450px);
}
@media (max-width: 991px) {
  .marketing-custom-wrapper {
    max-width: calc(100vw - 70px);
  }
}
</style>
