<template>
  <div class="wrapper">
    <router-view></router-view>
    <loader :isVisible="isLoading" />
    <div
      class="animated fadeIn"
      v-if="
        ['company-details', 'public-procurement-details'].indexOf($route.name) >
          -1 &&
        !isLoading &&
        !!company
      "
    >
      <h1 class="h3 px-1">{{ company.name }}</h1>
      <hr />

      <div v-if="company" class="row">
        <div class="col-md-6">
          <CompanyDetailsCard
            :company="company"
            @edit="editCompany"
            @delete="deleteCompany"
          />

          <CompanyFeaturesCard
            :company="company"
            @updated="companyFeaturesUpdated"
          />
        </div>
        <div class="col-md-6">
          <CompanyPublicProcurementToggleCard :company="company" />

          <CompanyCommercial :company="company" card />

          <CompanyListClientCard
            :company="company"
            @linked="clientLinked"
            @unlinked="clientUnlinked"
          />

          <CompanyLocalisationsCard
            v-if="company.id"
            :companyLocalisations="company.localisations"
            :companyId="company.id"
          />

          <CompanySignatureSheetsCard
            v-if="
              (company.signatureSheets && company.signatureSheets.length > 0) ||
              hasSignatureSheetsFeature
            "
            :company="company"
            :has-signature-sheets-feature="hasSignatureSheetsFeature"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "../components/Loader";
import CompanyLocalisationsCard from "../components/company/CompanyLocalisationsCard";
import CompanyCommercial from "../components/company/CompanyCommercial";
import CompanyListClientCard from "../components/company/CompanyListClientCard";
import CompanyDetailsCard from "../components/company/CompanyDetailsCard";
import CompanyFeaturesCard from "../components/company/CompanyFeaturesCard";
import CompanyPublicProcurementToggleCard from "../components/company/CompanyPublicProcurementToggleCard";
import CompanySignatureSheetsCard from "../components/company/CompanySignatureSheetsCard";

export default {
  components: {
    Loader,
    CompanyLocalisationsCard,
    CompanyCommercial,
    CompanyListClientCard,
    CompanyDetailsCard,
    CompanyFeaturesCard,
    CompanyPublicProcurementToggleCard,
    CompanySignatureSheetsCard,
  },

  data() {
    return {
      isLoading: true,
      company: {},
      companyFeatures: [],
    };
  },

  computed: {
    isPublicProcurementPage() {
      return this.$route.name === "public-procurement-details";
    },

    hasSignatureSheetsFeature() {
      return this.companyFeatures.includes("signatureSheets");
    },
  },

  created() {
    this.loadCompany();
  },

  methods: {
    async loadCompany() {
      const id = this.$route.params.id;

      try {
        const { data } = await this.$api.get(`/companies/${id}`);

        this.company = data;

        if (this.isPublicProcurementPage && !this.company.isPublicProcurement) {
          const route = this.$router.resolve({
            name: "company-details",
            params: {
              id: this.company.id,
            },
          });

          window.open(route.href, "_self");
        }
      } catch (e) {
        this.loadingError = `Impossible de charger ${
          this.isPublicProcurementPage ? "le marché public" : "l'entreprise"
        }`;
      } finally {
        this.isLoading = false;
      }
    },

    async editCompany(data) {
      try {
        await this.$api.put(`/companies/${this.company.id}`, data);

        this.company = { ...this.company, ...data };
      } catch (e) {
        this.loadingError = `Impossible de sauvegarder ${
          this.isPublicProcurementPage ? "le marché public" : "l'entreprise"
        }`;
      }
    },

    async deleteCompany() {
      try {
        await this.$api.delete(`/companies/${this.company.id}`);

        this.$router.push({
          name: this.isPublicProcurementPage
            ? "public-procurements-list"
            : "companies-list",
        });
      } catch (e) {
        this.loadingError = `Impossible de supprimer ${
          this.isPublicProcurementPage ? "le marché public" : "l'entreprise"
        }`;
      }
    },

    clientLinked(client) {
      if (!this.company.clients.some((c) => c.email === client.email)) {
        this.company.clients.push(client);
      }
    },

    clientUnlinked(clientEmail) {
      this.company.clients = this.company.clients.filter(
        (client) => client.email !== clientEmail
      );
    },

    companyFeaturesUpdated(features) {
      this.companyFeatures = features;
    },
  },
};
</script>
<style type="text/css" scoped>
.checkboxes {
  display: flex;
  justify-content: space-between;
  max-width: 600px;
}

.type {
  display: flex;
  align-items: center;
}

.type label {
  margin: 0;
}

.type input {
  height: 14px;
  width: 30px;
}
</style>
