<template>
  <modal
    v-if="userHasWritePermission"
    :title="
      form.id
        ? `Éditer le modèle de feuille d'émargement`
        : `Ajouter un modèle de feuille d'émargement`
    "
    :value="show"
    large
    effect="fade/zoom"
    cancelText="Fermer"
    :okText="form.id ? 'Modifier' : 'Créer'"
    @ok="createOrUpdateSignatureSheetTemplate"
    @cancel="closeModal"
  >
    <Loader :is-visible="loading" />

    <div class="modal-body">
      <form>
        <div class="form-group">
          <label>Nom</label>
          <el-input
            type="text"
            v-model="form.label"
            placeholder="Nom du modèle"
          />

          <small v-if="errors.label" class="text-danger">{{
            errors.label
          }}</small>
        </div>

        <div class="form-group">
          <label>Entreprise</label>
          <v-select
            v-if="!form.id"
            v-model="form.company"
            :options="companiesOptions"
            :disabled="!!company"
          ></v-select>
          <div
            v-else-if="form.company && form.company.label"
            class="font-weight-bold"
          >
            {{ form.company.label }}
          </div>

          <small v-if="errors.company" class="text-danger">{{
            errors.company
          }}</small>
        </div>

        <div class="form-group">
          <label>Lot</label>
          <v-select
            v-if="!form.id"
            v-model="form.sessionsBatch"
            :options="sessionsBatchesOptions"
          >
            <template #no-options>
              {{
                form.company
                  ? "Aucun lot disponible"
                  : "Veuillez sélectionner une entreprise"
              }}
            </template>
          </v-select>
          <div
            v-else-if="form.sessionsBatch && form.sessionsBatch.label"
            class="font-weight-bold"
          >
            {{ form.sessionsBatch.label }}
          </div>

          <small v-if="errors.sessionsBatch" class="text-danger">{{
            errors.sessionsBatch
          }}</small>
        </div>

        <div v-if="form.id" class="alert alert-info">
          <i class="fa fa-info-circle mr-05"></i> Si vous modifiez les champs ou
          les entrées du modèle, les feuilles d'émargement qui y sont reliées et
          qui ont le statut "En attente" seront également mises à jour
        </div>

        <div class="form-group">
          <template v-if="importTemplate">
            <dropzone
              id="file"
              :url="apiImportUrl"
              :headers="apiAuthorization"
              acceptedFileTypes="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv"
              v-on:vdropzone-success="handleImportedData"
            >
              <div class="dz-default dz-message">
                <span
                  ><i class="icon-cloud-upload"></i><br />Glissez un fichier
                  (format Excel ou CSV)</span
                >
              </div>
            </dropzone>
            <button
              type="button"
              class="btn btn-sm btn-secondary mt-1"
              @click="importTemplate = false"
            >
              <i class="fa fa-ban mr-05"></i>Annuler
            </button>
          </template>
          <button
            v-else
            type="button"
            class="btn btn-sm btn-primary"
            @click="importTemplate = true"
          >
            <i class="fa fa-upload"></i> Importer
          </button>
        </div>

        <div class="form-group">
          <label>Champs</label>
          <div class="d-flex flex-wrap">
            <div
              v-for="(field, index) in form.fields"
              :key="`modal-form-field-${index}`"
              class="col-2"
              style="padding: 0 5px 0 0; margin-bottom: 5px"
            >
              <input
                :value="field"
                type="text"
                class="form-control"
                @input="(e) => updateField(index, e.target.value)"
              />
            </div>

            <div class="d-flex" style="margin-bottom: 5px">
              <button
                v-if="form.fields.length > 1"
                type="button"
                class="btn btn-sm btn-secondary mr-05"
                @click="form.fields.pop()"
              >
                <i class="fa fa-minus"></i>
              </button>
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                @click="form.fields.push('')"
              >
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>

          <small v-if="errors.fields" class="text-danger">{{
            errors.fields
          }}</small>
        </div>

        <div class="form-group">
          <label>Entrées</label>
          <div v-if="form.fields.length === 0" class="text-muted">
            Veuillez ajouter des champs
          </div>
          <div style="max-height: 300px; overflow-y: auto">
            <div
              v-for="(entry, index) in form.entries"
              :key="`modal-form-entry-${index}`"
              class="d-flex flex-wrap mb-1"
            >
              <div
                v-for="i in form.fields.length"
                :key="`modal-form-entry-${index}-entryField-${i - 1}`"
                class="col-2"
                style="padding: 0 5px 0 0; margin-bottom: 5px"
              >
                <input
                  :value="entry[i - 1]"
                  type="text"
                  class="form-control"
                  @input="(e) => updateEntryField(index, i - 1, e.target.value)"
                />
              </div>
            </div>
          </div>
          <div v-if="form.fields.length > 0" class="d-flex">
            <button
              v-if="form.entries.length > 0"
              type="button"
              class="btn btn-sm btn-secondary mr-05"
              @click="form.entries.pop()"
            >
              <i class="fa fa-minus"></i>
            </button>
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              @click="addEntry"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>

          <small v-if="errors.entries" class="text-danger">{{
            errors.entries
          }}</small>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import axios from "axios";

import Modal from "vue-strap/src/Modal";
import Loader from "../Loader";
import Dropzone from "vue2-dropzone";

export default {
  components: {
    Modal,
    Loader,
    Dropzone,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    template: {
      type: Object,
      default: null,
    },

    company: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      form: {
        id: null,
        label: "",
        company: null,
        sessionsBatch: null,
        fields: [],
        entries: [],
      },
      errors: {
        label: null,
        company: null,
        sessionsBatch: null,
        fields: null,
        entries: null,
      },
      companies: [],
      importTemplate: false,
    };
  },

  computed: {
    userHasWritePermission() {
      return (
        this.hasPermission(this.$store.state.user, "COMPANIES_COMPANY_WRITE") ||
        this.hasPermission(this.$store.state.user, "PUBLIC_PROCUREMENTS_WRITE")
      );
    },

    companiesOptions() {
      return this.companies.map((company) => ({
        label: company.name,
        value: company.id,
      }));
    },

    sessionsBatchesOptions() {
      if (this.form.company) {
        const company = this.companies.find(
          (c) => c.id === this.form.company.value
        );

        if (company?.sessionsBatches) {
          return company.sessionsBatches.map((sessionsBatch) => ({
            label: sessionsBatch.label,
            value: sessionsBatch.id,
          }));
        }
      }

      return [];
    },

    apiImportUrl() {
      return axios.defaults.baseURL + "signature-sheets/import";
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token"),
      };
    },
  },

  watch: {
    template(value) {
      if (value) {
        this.form = {
          id: value.id,
          label: value.label,
          company: {
            label: value.sessionsBatch?.company?.name,
            value: value.sessionsBatch?.company?.id,
          },
          sessionsBatch: {
            label: value.sessionsBatch?.label,
            value: value.sessionsBatch?.id,
          },
          fields: value.fields,
          entries: value.entries,
        };
      } else {
        this.resetForm();
      }
    },

    company: {
      handler() {
        if (this.company) {
          this.companies = [this.company];

          this.form.company = {
            label: this.company.name,
            value: this.company.id,
          };
        } else {
          this.fetchCompanies();
        }
      },
      deep: true,
    },
  },

  created() {
    if (this.company) {
      this.companies = [this.company];

      this.form.company = {
        label: this.company.name,
        value: this.company.id,
      };
    } else {
      this.fetchCompanies();
    }
  },

  methods: {
    async fetchCompanies() {
      try {
        const { data } = await this.$api.get("/companies/simples", {
          params: { features: ["signatureSheets"] },
        });

        this.companies = data;
      } catch (e) {
        this.companies = [];
      }
    },

    closeModal() {
      this.$emit("close");

      this.resetForm();
    },

    resetForm() {
      this.form = {
        id: null,
        label: "",
        company: null,
        sessionsBatch: null,
        fields: [],
        entries: [],
      };

      this.clearErrors();
    },

    clearErrors() {
      this.errors = {
        label: null,
        company: null,
        sessionsBatch: null,
        fields: null,
        entries: null,
      };
    },

    checkFormErrors() {
      if (!this.form.label || !this.form.label.trim()) {
        this.errors.label = "Le nom est obligatoire";
      }

      if (!this.form.company?.value) {
        this.errors.company = "Veuillez choisir une entreprise";
      }

      if (!this.form.sessionsBatch?.value) {
        this.errors.sessionsBatch = "Veuillez choisir un lot";
      }

      if (this.form.fields.length === 0) {
        this.errors.fields = "Veuillez ajouter des champs";
      } else if (this.form.fields.some((field) => !field || !field.trim())) {
        this.errors.fields = "Un ou plusieurs champs sont vides";
      }

      if (this.form.entries.some((entry) => entry.every((field) => !field))) {
        this.errors.entries = "Une ou plusieurs entrées sont vides";
      }
    },

    async createOrUpdateSignatureSheetTemplate() {
      try {
        this.clearErrors();

        this.checkFormErrors();

        if (Object.values(this.errors).some((error) => !!error)) {
          return;
        }

        this.loading = true;

        if (this.form.id) {
          const { data } = await this.$api.patch(
            `/signature-sheets/templates/${this.form.id}`,
            this.form
          );

          this.$emit("updated", data);
        } else {
          const { data } = await this.$api.post("/signature-sheets/templates", {
            ...this.form,
            sessionsBatchId: this.form.sessionsBatch.value,
          });

          const company = this.companies.find(
            (c) => c.id === this.form.company.value
          );
          const sessionsBatch = company.sessionsBatches.find(
            (sb) => sb.id === this.form.sessionsBatch.value
          );

          this.$emit("added", {
            ...data,
            signatureSheets: [],
            sessionsBatch: {
              ...sessionsBatch,
              company,
            },
          });
        }

        this.$successToast(
          `Le modèle de feuille d'émargement a bien été ${
            this.form.id ? "modifié" : "créé"
          }`
        );

        this.closeModal();
      } catch (e) {
        this.$errorToast(
          `Impossible de ${
            this.form.id ? "modifier" : "créer"
          } le modèle de feuille d'émargement`
        );
      } finally {
        this.loading = false;
      }
    },

    updateField(index, value) {
      this.form.fields[index] = value;
    },

    updateEntryField(entryIndex, entryFieldIndex, value) {
      this.form.entries[entryIndex][entryFieldIndex] = value;
    },

    addEntry() {
      this.form.entries.push(this.form.fields.map(() => ""));
    },

    handleImportedData(file, response) {
      const { label, fields, entries } = response;

      this.form.label = label;
      this.form.fields = fields;
      this.form.entries = entries;

      this.importTemplate = false;
    },
  },
};
</script>
