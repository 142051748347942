export default {
  getMerchant: (state) => (id) => {
    return state.merchants.filter((merchant) => merchant.id === id)[0] || null;
  },

  getInvoiceSerie: (state) => (id) => {
    return (
      state.invoiceSeries.filter((invoiceSerie) => invoiceSerie.id === id)[0] ||
      null
    );
  },

  getDepartments: (state) => {
    return state.departments;
  },

  getLanguages: (state) => {
    return state.languages;
  },

  getLostReasons: (state) => {
    return state.lostReasons;
  },

  getCommonUtm: (state) => {
    return state.commonUtm;
  },

  getFidelisationRanks: (state) => {
    return state.fidelisationRanks;
  },

  getClientStatus: (state) => {
    return state.clientStatus;
  },

  getProspectStatus: (state) => {
    return state.prospectStatus;
  },

  getCoachClientStatus: (state) => {
    return state.coachClientStatus;
  },

  getCoachStatus: (state) => {
    return state.coachStatus;
  },

  getCodesTypeVoie: (state) => {
    return state.codesTypeVoie;
  },

  getCodeInseePays: (state) => {
    return state.codesInseePays;
  },

  getLiveBanSettings: (state) => {
    return state.liveBan;
  },

  getGenders: (state) => {
    return state.genders;
  },

  getOrderStatusGroups: (state) => {
    return state.orderStatus;
  },

  getOrderStatusFollowedGroups: (state) => {
    return state.orderStatusFollowed;
  },

  getOrderStatusProblematic: (state) => {
    return state.orderStatusProblematic;
  },

  getMenuStatsOptions: (state) => {
    return state.menuStatsOptions;
  },

  getOrderStatus: (state) => {
    return state.orderStatus[0].status;
  },

  getSponsorshipStatus: (state) => {
    return state.sponsorship.status;
  },

  getOrderTypes: (state) => {
    return state.orderTypes;
  },

  getLettresVoie: (state) => {
    return state.lettresVoie;
  },

  getPhoneCodes: (state) => state.phoneCodes,

  getCoachRanges: (state) => state.coachRanges,

  getCompanyStatuts: (state) => state.companyStatuts,

  getCompanyLost: (state) => state.companyLost,

  getPreferredContactMethodOptions: (state) =>
    state.preferredContactMethodOptions,

  getSignatureSheetStatus: (state) => state.signatureSheetStatus,
};
