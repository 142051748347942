<template>
  <modal
    title="Exporter"
    :value="!!sessionsBatch"
    effect="fade/zoom"
    cancelText="Fermer"
    okText="Télécharger"
    @ok="exportSignatureSheets"
    @cancel="closeModal"
  >
    <Loader :is-visible="loading" />

    <div v-if="sessionsBatch" class="modal-body">
      <div class="alert alert-info">
        Vous vous apprêtez à exporter les feuilles d'émargement des séances du
        lot
        <strong>{{ sessionsBatch.label }}</strong> effectuées au cours de la
        période sélectionnée et validées.
      </div>

      <form class="row">
        <div class="form-group col-sm-6">
          <label>Du</label>
          <datepicker
            v-model="form.begin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>

        <div class="form-group col-sm-6">
          <label>Au</label>
          <datepicker
            v-model="form.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
      </form>

      <div v-if="sessionsBatch.sessions">
        <strong>{{ completedSessionsDuringPeriod }}</strong> séance(s)
        effectuée(s) au cours de cette période
      </div>
    </div>
  </modal>
</template>

<script>
import moment from "moment";

import DownloadFile from "../../mixin/DownloadFile";
import Datepicker from "vuejs-datepicker";
import Modal from "vue-strap/src/Modal";
import Loader from "../Loader";

export default {
  components: {
    Modal,
    Loader,
    Datepicker,
  },

  mixins: [DownloadFile],

  props: {
    sessionsBatch: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      form: {
        begin: moment().startOf("month").toDate(),
        end: moment().endOf("month").toDate(),
      },
    };
  },

  computed: {
    completedSessionsDuringPeriod() {
      if (!this.sessionsBatch?.sessions) {
        return 0;
      }

      if (!this.form.begin && !this.form.end) {
        return this.sessionsBatch.sessions.filter(
          (s) => !!s.scheduledAt && s.completed
        ).length;
      }

      return this.sessionsBatch.sessions.filter((s) => {
        if (!s.scheduledAt || !s.completed) {
          return false;
        }

        if (this.form.begin && this.form.end) {
          return moment(s.scheduledAt).isBetween(
            this.form.begin,
            this.form.end,
            "day",
            "[]"
          );
        }

        if (this.form.begin) {
          return moment(s.scheduledAt).isSameOrAfter(this.form.begin, "day");
        }

        if (this.form.end) {
          return moment(s.scheduledAt).isSameOrBefore(this.form.end, "day");
        }

        return false;
      }).length;
    },
  },

  methods: {
    closeModal() {
      this.$emit("close");

      this.resetForm();
    },

    resetForm() {
      this.form = {
        begin: moment().startOf("month").toDate(),
        end: moment().endOf("month").toDate(),
      };
    },

    async exportSignatureSheets() {
      try {
        this.loading = true;

        await this.downloadSecuredFile(
          `/sessions-batches/${this.sessionsBatch.id}/export?begin=${this.form.begin}&end=${this.form.end}`
        );

        this.closeModal();
      } catch (e) {
        if (e.message.match(/Empty/i)) {
          this.$warningToast("Aucune feuille d'émargement pour cette période");
        } else {
          this.$errorToast("Téléchargement impossible");
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
