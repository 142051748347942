<template>
  <div class="card">
    <div class="card-header d-flex justify-content-between">
      <div><i class="icon-graph"></i> Marketing Data</div>

      <button class="btn btn-primary btn-sm" @click="openCreateMarketingModal">
        <i class="fa fa-plus"></i>&nbsp; Ajouter
      </button>
    </div>

    <div class="card-block">
      <div class="w-100 mw-100 overflow-auto">
        <displayMarketing :marketings="marketings" />
      </div>
    </div>

    <CreateMarketingModal
      :openModal="openModal"
      @create="createMarketing"
      @close="closeCreateMarketingModal"
    />
  </div>
</template>

<script>
import DisplayMarketing from "./DisplayMarketing.vue";
import CreateMarketingModal from "./CreateMarketingModal.vue";

export default {
  components: {
    DisplayMarketing,
    CreateMarketingModal,
  },

  props: {
    marketings: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      openModal: false,
    };
  },

  computed: {
    checkPermission() {
      return this.hasPermission(this.$store.state.user, "CLIENTS_WRITE");
    },
  },

  methods: {
    openCreateMarketingModal() {
      this.openModal = true;
    },

    closeCreateMarketingModal() {
      this.openModal = false;
    },

    createMarketing(data) {
      this.$emit("create", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.overflow {
  overflow: auto;
}
</style>
