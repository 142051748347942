<template>
  <div class="card">
    <div class="card-header"><i class="fa fa-file-text-o"></i> Émargement</div>

    <div class="card-block">
      <dl class="row">
        <dt class="col-sm-3">Lot</dt>
        <dd class="col-sm-9">
          <v-select
            v-model="selectedSessionsBatch"
            :options="sessionsBatches"
            @input="selectedSignatureSheetTemplate = null"
          ></v-select>
          <button
            v-if="userHasWritePermission && hasSignatureSheetsFeature"
            type="button"
            class="btn btn-sm btn-primary"
            style="margin-top: 5px"
            @click="showSessionsBatchModal = true"
          >
            <i class="fa fa-plus mr-05"></i> Ajouter
          </button>
        </dd>
        <dt class="col-sm-3">Modèle</dt>
        <dd class="col-sm-9">
          <v-select
            v-model="selectedSignatureSheetTemplate"
            :options="filteredSignatureSheetTemplates"
          ></v-select>
          <button
            v-if="userHasWritePermission && hasSignatureSheetsFeature"
            type="button"
            class="btn btn-sm btn-primary"
            style="margin-top: 5px"
            @click="showSignatureSheetTemplateModal = true"
          >
            <i class="fa fa-plus mr-05"></i> Ajouter
          </button>
        </dd>
      </dl>

      <Tabs v-model="activeTab">
        <Tab
          v-for="status in signatureSheetStatus"
          :key="status.value"
          :header="`${status.label} (${
            sortedByStatusSignatureSheets[status.value].length
          })`"
        >
          <SignatureSheetsTable
            :signature-sheets="sortedByStatusSignatureSheets[status.value]"
            simplified
            scroll
            @updated="signatureSheetUpdated"
          />
        </Tab>
      </Tabs>
    </div>

    <SessionsBatchModal
      :show="showSessionsBatchModal"
      :company="companyWithCurrentData"
      @added="sessionsBatchAdded"
      @close="showSessionsBatchModal = false"
    />
    <SignatureSheetTemplateModal
      :show="showSignatureSheetTemplateModal"
      :company="companyWithCurrentData"
      @added="signatureSheetTemplateAdded"
      @close="showSignatureSheetTemplateModal = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import SignatureSheetsTable from "./SignatureSheetsTable";
import SignatureSheetTemplateModal from "./SignatureSheetTemplateModal";
import SessionsBatchModal from "./SessionsBatchModal";

export default {
  components: {
    Tabs,
    Tab,
    SignatureSheetsTable,
    SignatureSheetTemplateModal,
    SessionsBatchModal,
  },

  props: {
    company: {
      type: Object,
      required: true,
    },

    hasSignatureSheetsFeature: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      activeTab: 0,
      signatureSheets: [],
      sessionsBatches: [],
      signatureSheetTemplates: [],
      selectedSessionsBatch: null,
      selectedSignatureSheetTemplate: null,
      showSessionsBatchModal: false,
      showSignatureSheetTemplateModal: false,
    };
  },

  computed: {
    ...mapGetters({
      signatureSheetStatus: "getSignatureSheetStatus",
    }),

    filteredSignatureSheetTemplates() {
      return this.selectedSessionsBatch
        ? this.signatureSheetTemplates.filter(
            (template) =>
              template.sessionsBatchId === this.selectedSessionsBatch.id
          )
        : this.signatureSheetTemplates;
    },

    filteredSignatureSheets() {
      return this.signatureSheets.filter((sheet) => {
        if (this.selectedSessionsBatch && this.selectedSignatureSheetTemplate) {
          return (
            sheet.session?.sessionsBatchId === this.selectedSessionsBatch.id &&
            sheet.signatureSheetTemplateId ===
              this.selectedSignatureSheetTemplate.id
          );
        }

        if (this.selectedSessionsBatch) {
          return (
            sheet.session?.sessionsBatchId === this.selectedSessionsBatch.id
          );
        }

        if (this.selectedSignatureSheetTemplate) {
          return (
            sheet.signatureSheetTemplateId ===
            this.selectedSignatureSheetTemplate.id
          );
        }

        return true;
      });
    },

    sortedByStatusSignatureSheets() {
      const status = {};

      this.signatureSheetStatus.forEach((s) => {
        status[s.value] = this.filteredSignatureSheets.filter(
          (sheet) => sheet.status === s.value
        );
      });

      return status;
    },

    userHasWritePermission() {
      return (
        this.hasPermission(this.$store.state.user, "COMPANIES_COMPANY_WRITE") ||
        this.hasPermission(this.$store.state.user, "PUBLIC_PROCUREMENTS_WRITE")
      );
    },

    companyWithCurrentData() {
      return {
        ...this.company,
        signatureSheets: this.signatureSheets,
        sessionsBatches: this.sessionsBatches,
      };
    },
  },

  watch: {
    company: {
      handler() {
        this.sessionsBatches = [...this.company.sessionsBatches];
        this.signatureSheetTemplates = [];

        this.sessionsBatches.forEach((sessionBatch) => {
          if (sessionBatch.signatureSheetTemplates?.length > 0) {
            sessionBatch.signatureSheetTemplates.forEach((template) => {
              if (
                !this.signatureSheetTemplates.some((t) => t.id === template.id)
              ) {
                this.signatureSheetTemplates.push(template);
              }
            });
          }
        });

        this.signatureSheets = [...this.company.signatureSheets].map(
          (sheet) => {
            const sessionsBatch = this.sessionsBatches.find(
              (batch) => batch.id === sheet.session?.sessionsBatchId
            );
            const signatureSheetTemplate = this.signatureSheetTemplates.find(
              (template) => template.id === sheet.signatureSheetTemplateId
            );

            return {
              ...sheet,
              session: {
                ...sheet.session,
                sessionsBatch,
              },
              signatureSheetTemplate,
            };
          }
        );
      },
      deep: true,
    },
  },

  mounted() {
    this.sessionsBatches = [...this.company.sessionsBatches];

    this.sessionsBatches.forEach((sessionBatch) => {
      if (sessionBatch.signatureSheetTemplates?.length > 0) {
        sessionBatch.signatureSheetTemplates.forEach((template) => {
          if (!this.signatureSheetTemplates.some((t) => t.id === template.id)) {
            this.signatureSheetTemplates.push(template);
          }
        });
      }
    });

    this.signatureSheets = [...this.company.signatureSheets].map((sheet) => {
      const sessionsBatch = this.sessionsBatches.find(
        (batch) => batch.id === sheet.session?.sessionsBatchId
      );
      const signatureSheetTemplate = this.signatureSheetTemplates.find(
        (template) => template.id === sheet.signatureSheetTemplateId
      );

      return {
        ...sheet,
        session: {
          ...sheet.session,
          sessionsBatch,
        },
        signatureSheetTemplate,
      };
    });
  },

  methods: {
    signatureSheetUpdated(signatureSheetData) {
      this.signatureSheets = this.signatureSheets.map((sheet) => {
        if (sheet.id === signatureSheetData.id) {
          return {
            ...sheet,
            ...signatureSheetData,
          };
        }

        return sheet;
      });
    },

    sessionsBatchAdded(sessionsBatch) {
      this.sessionsBatches.unshift(sessionsBatch);
    },

    signatureSheetTemplateAdded(signatureSheetTemplate) {
      this.signatureSheetTemplates.unshift(signatureSheetTemplate);
    },
  },
};
</script>
