<template>
  <div class="wrapper">
    <router-view></router-view>
    <Loader :is-visible="loading" />

    <div
      class="animated fadeIn"
      v-if="['signature-sheets-templates'].indexOf($route.name) > -1"
    >
      <SignatureSheetTemplatesFilters @apply="applyFilters" />

      <div class="card">
        <div class="card-header">
          <i class="fa fa-file-o"></i>
          Modèles de feuilles d'émargement ({{ total }})

          <button
            v-if="userHasWritePermission"
            type="button"
            class="btn btn-sm btn-primary float-right"
            @click="modal.show = true"
          >
            <i class="fa fa-plus mr-05"></i> Ajouter
          </button>
        </div>

        <div class="card-block">
          <SignatureSheetTemplatesTable
            :signature-sheet-templates="signatureSheetTemplates"
            @edit="editTemplate"
            @delete="deleteTemplate"
          />

          <paginate
            ref="paginate"
            v-model="currentPage"
            :page-count="pageCount"
            :click-handler="changePage"
            prev-text="Précédent"
            next-text="Suivant"
            container-class="pagination"
            class="mt-1"
          ></paginate>
        </div>
      </div>
    </div>

    <SignatureSheetTemplateModal
      :show="modal.show"
      :template="modal.template"
      @added="templateAdded"
      @updated="templateUpdated"
      @close="closeModal"
    />

    <modal
      v-if="userHasWritePermission"
      title="Supprimer le modèle"
      :value="!!deleteModal.template"
      effect="fade/zoom"
      cancelText="Annuler"
      okText="Supprimer"
      @ok="confirmDeleteTemplate"
      @cancel="resetDelete"
    >
      <div class="modal-body">
        <p>
          Êtes-vous sûr de vouloir supprimer le modèle de feuille d'émargement
          <strong>{{ deleteModal.template?.label }}</strong> ?
        </p>

        <div class="form-group">
          <label>Supprimer les feuilles d'émargement "En attente" ?</label>
          <el-switch
            v-model="deleteModal.deletePendingSignatureSheets"
            active-text="Oui"
            inactive-text="Non"
          ></el-switch>
        </div>

        <div
          v-if="deleteModal.deletePendingSignatureSheets"
          class="alert alert-danger"
        >
          Toutes les feuilles d'émargement reliées à ce modèle avec le statut
          "En attente" seront supprimées
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import Loader from "../components/Loader";
import Paginate from "vuejs-paginate";
import SignatureSheetTemplatesFilters from "../components/company/SignatureSheetTemplatesFilters";
import SignatureSheetTemplatesTable from "../components/company/SignatureSheetTemplatesTable";
import SignatureSheetTemplateModal from "../components/company/SignatureSheetTemplateModal";

export default {
  components: {
    Loader,
    Modal,
    Paginate,
    SignatureSheetTemplatesFilters,
    SignatureSheetTemplatesTable,
    SignatureSheetTemplateModal,
  },

  data() {
    return {
      loading: false,
      signatureSheetTemplates: [],
      total: 0,
      currentPage: 1,
      limit: 15,
      filters: {
        createdAtBegin: null,
        createdAtEnd: null,
        updatedAtBegin: null,
        updatedAtEnd: null,
        companyId: null,
        sessionsBatchId: null,
      },
      modal: {
        show: false,
        template: null,
      },
      deleteModal: {
        template: null,
        deletePendingSignatureSheets: true,
      },
    };
  },

  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },

    pageCount() {
      if (!this.total) {
        return 0;
      }

      return Math.ceil(this.total / this.limit);
    },

    userHasWritePermission() {
      return (
        this.hasPermission(this.$store.state.user, "COMPANIES_COMPANY_WRITE") ||
        this.hasPermission(this.$store.state.user, "PUBLIC_PROCUREMENTS_WRITE")
      );
    },
  },

  watch: {
    currentPage() {
      this.$refs.paginate.selected = this.currentPage - 1;
    },
  },

  mounted() {
    this.fetchSignatureSheetTemplates();
  },

  methods: {
    async fetchSignatureSheetTemplates() {
      try {
        this.loading = true;

        const { data } = await this.$api.get("/signature-sheets/templates", {
          params: {
            offset: this.offset,
            limit: this.limit,
            ...this.filters,
          },
        });

        this.total = data.total;
        this.signatureSheetTemplates = data.templates;
      } catch (e) {
        this.signatureSheetTemplates = [];
      } finally {
        this.loading = false;
      }
    },

    changePage(page) {
      this.currentPage = page;

      this.fetchSignatureSheetTemplates();
    },

    applyFilters(filters) {
      this.filters = { ...this.filters, ...filters };

      this.changePage(1);
    },

    closeModal() {
      this.modal.show = false;
      this.modal.template = null;
    },

    editTemplate(template) {
      this.modal.template = template;
      this.modal.show = true;
    },

    deleteTemplate(template) {
      this.deleteModal.template = template;
    },

    resetDelete() {
      this.deleteModal.template = null;
      this.deleteModal.deletePendingSignatureSheets = true;
    },

    async confirmDeleteTemplate() {
      try {
        this.loading = true;

        await this.$api.delete(
          `/signature-sheets/templates/${this.deleteModal.template.id}`,
          {
            data: {
              deletePendingSignatureSheets:
                this.deleteModal.deletePendingSignatureSheets,
            },
          }
        );

        this.$successToast("Modèle supprimé avec succès");

        this.signatureSheetTemplates = this.signatureSheetTemplates.filter(
          (t) => t.id !== this.deleteModal.template.id
        );

        this.resetDelete();
      } catch (e) {
        this.$errorToast("Impossible de supprimer le modèle");
      } finally {
        this.loading = false;
      }
    },

    templateAdded(template) {
      this.signatureSheetTemplates.unshift(template);
    },

    templateUpdated(template) {
      this.signatureSheetTemplates = this.signatureSheetTemplates.map((t) =>
        t.id === template.id ? { ...t, ...template } : t
      );
    },
  },
};
</script>
