<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>

    <div class="card-block">
      <div class="row">
        <div class="col-sm-3 form-group">
          <label>Créé après le</label>
          <datepicker
            v-model="filters.createdAtBegin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>

        <div class="col-sm-3 form-group">
          <label>Créé avant le</label>
          <datepicker
            v-model="filters.createdAtEnd"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>

        <div class="col-sm-3 form-group">
          <label>Mis à jour après le</label>
          <datepicker
            v-model="filters.updatedAtBegin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>

        <div class="col-sm-3 form-group">
          <label>Mis à jour avant le</label>
          <datepicker
            v-model="filters.updatedAtEnd"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 form-group">
          <label>Entreprise</label>
          <v-select
            v-model="filters.companyId"
            :options="companiesOptions"
            :reduce="({ value }) => value"
            @search="searchCompanies"
            @input="selectCompany"
          ></v-select>
        </div>
        <div class="col-sm-4 form-group">
          <label>Lot</label>
          <v-select
            v-model="filters.sessionsBatchId"
            :options="sessionsBatchesOptions"
            :reduce="({ value }) => value"
            @search="searchSessionsBatchesOptions"
          ></v-select>
        </div>
        <div class="col-sm-4 form-group">
          <label>Nom</label>
          <input
            class="form-control"
            v-model="filters.label"
            placeholder="Nom du modèle"
          />
        </div>
      </div>
    </div>

    <div class="card-footer">
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="applyFilters"
      >
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
    </div>
  </div>
</template>

<script>
import debounce from "lodash.debounce";

import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },

  data() {
    return {
      filters: {
        createdAtBegin: null,
        createdAtEnd: null,
        updatedAtBegin: null,
        updatedAtEnd: null,
        companyId: null,
        sessionsBatchId: null,
        label: null,
      },
      companiesOptions: [],
      sessionsBatchesOptions: [],
    };
  },

  methods: {
    applyFilters() {
      this.$emit("apply", this.filters);
    },

    searchCompanies(search, loading) {
      if (search !== "") {
        this.fetchCompanies(search, loading, this);
      }
    },

    fetchCompanies: debounce(async (search, loading, vm) => {
      if (!search || search.length < 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.post("/companies/search", {
        query: search,
      });

      vm.companiesOptions = data.map((c) => ({
        label: c.name,
        value: c.id,
      }));

      loading(false);

      return vm.companiesOptions;
    }, 300),

    async selectCompany(input) {
      if (input?.value) {
        const { data } = await this.$api.get("/sessions-batches", {
          params: { companyId: input.value },
        });

        this.sessionsBatchesOptions = data.map((c) => ({
          label: c.label,
          value: c.id,
        }));

        this.filters.sessionsBatchId = null;
      }
    },

    searchSessionsBatchesOptions(search, loading) {
      if (search !== "") {
        this.fetchSessionsBatchesOptions(search, loading, this);
      }
    },

    fetchSessionsBatchesOptions: debounce(async (search, loading, vm) => {
      if (!search || search.length < 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.get("/sessions-batches", {
        params: { label: search },
      });

      vm.sessionsBatchesOptions = data.map((c) => ({
        label: c.label,
        value: c.id,
      }));

      loading(false);

      return vm.sessionsBatchesOptions;
    }, 300),
  },
};
</script>
