<template>
  <div class="card sessions-wrapper">
    <div class="card-header">
      <i class="icon-directions"></i> Séances
      <button
        type="button"
        class="btn btn-sm btn-warning float-right"
        v-if="
          order.status === 'pending' &&
          sessions.length != order.session &&
          hasPermission($store.state.user, 'ORDERS_WRITE')
        "
        @click="addAllowed = true"
      >
        <i class="fa fa-unlock"></i>&nbsp; Autoriser des séances temporaires
      </button>
      <button
        v-else-if="
          order.status === 'partial' &&
          hasPermission($store.state.user, 'ORDERS_WRITE')
        "
        class="btn btn-sm btn-danger float-right"
        @click="forcePayed"
      >
        Forcer le statut "payée"
      </button>
    </div>
    <div v-if="sessions.length > 0" class="card-block">
      <Sessions
        :sessions-data="sessions"
        :live="!!order.live"
        :visio="order.visio && order.visio.hasVisio && !!order.visio.roomUuid"
        :show-nb-of-participants="order.requiredSessionUserCount"
        @change-coach="reload"
      />
    </div>
    <div v-else class="card-block">
      <small>La commande n'a pas été payée.</small>
      <button
        v-if="
          !order.invoice && hasPermission($store.state.user, 'ORDERS_WRITE')
        "
        class="btn btn-sm float-right"
        @click="forceInvoice"
      >
        Créer une facture
      </button>
    </div>

    <modal
      title="Autoriser des séances temporaires"
      v-model="addAllowed"
      @ok="handleAddAllowed"
      effect="fade/zoom"
    >
      <v-select v-model="allowed" :options="allowedOptions"></v-select>
    </modal>
  </div>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import Sessions from "../components/Sessions";

export default {
  components: {
    Modal,
    Sessions,
  },

  props: {
    sessions: {
      type: Array,
      required: true,
    },

    order: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      addAllowed: false,
      allowedOptions: [],
      allowed: null,
    };
  },

  mounted() {
    for (let i = 0; i <= 200; i++) {
      this.allowedOptions.push({
        label: this.formatPack(i.toString()),
        value: i.toString(),
      });
    }
  },

  methods: {
    handleAddAllowed() {
      if (!this.allowed) {
        return false;
      }

      this.$confirm({
        message: "Attention, cette action est irréversible !",
        onConfirm: async () => {
          await this.$api.put(`/orders/${this.order.id}`, {
            allowed: this.allowed.value,
          });

          this.addAllowed = false;
          this.reload();
        },
      });
    },

    forcePayed() {
      this.$confirm({
        message: "Attention, cette action est irréversible !",
        onConfirm: async () => {
          await this.$api.put(`/orders/force/${this.order.id}`, {
            status: "payed",
          });

          this.reload();
        },
      });
    },

    forceInvoice() {
      this.$confirm({
        message:
          "Créer une facture ? Attention, cette action est irréversible !",
        onConfirm: async () => {
          await this.$api.put(`/orders/force/${this.order.id}`, {
            invoice: true,
          });

          this.reload();
        },
      });
    },

    reload() {
      this.$emit("reload");
    },
  },
};
</script>

<style scoped>
.sessions-wrapper {
  max-width: calc(100vw - 320px);
}

@media (max-width: 991px) {
  .sessions-wrapper {
    max-width: calc(100vw - 70px);
  }
}

.aside-menu-fixed.sidebar-hidden .sessions-wrapper {
  max-width: calc(100vw - 70px);
}
</style>
