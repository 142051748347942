<template>
  <footer class="app-footer">
    Just Coaching &copy; 2017 -
    {{ new Date().getFullYear() }}
    <span @click="increment">: v.1.24.0 - 11 Février 2025</span>
    <span :style="`color:red; ${visible ? 'display:inline' : 'display:none'}`">
      Voilà Clément j'éspère que tu es heureux de cette nouvelle feature
    </span>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      value: 0,
      visible: false,
    };
  },
  methods: {
    increment() {
      this.value++;

      if (this.value % 5 === 0) {
        this.visible = !this.visible;
      }
    },
  },
};
</script>
