<template>
  <button
    class="btn btn-sm btn-outline-info"
    :disabled="loading"
    @click="download"
  >
    <i class="fa fa-download"></i>
    <slot />
  </button>
</template>

<script>
import DownloadFile from "../../mixin/DownloadFile";

export default {
  mixins: [DownloadFile],

  props: {
    signatureSheet: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async download() {
      try {
        this.loading = true;

        await this.downloadSecuredFile(
          `/signature-sheets/${this.signatureSheet.id}/download`
        );
      } catch (e) {
        this.$errorToast("Impossible de télécharger la feuille d'émargement");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
