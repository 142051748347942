import Vue from "vue";

function checkErrors(error) {
  try {
    const data = error.response.data;

    switch (error.response.status) {
      case 400:
        Vue.$toast.open({
          message: `400 - Il y a une erreur dans la saisie du formulaire. (${data})`,
          type: "error",
          duration: 10000,
        });
        break;
      case 409:
        Vue.$toast.open({
          message: `409 - Ce contenu existe déjà ou rentre en conflit avec l'état du serveur. (${data})`,
          type: "error",
          duration: 10000,
        });
        break;
      case 500:
        Vue.$toast.open({
          message: `500 - Une erreur serveur est survenue ! (${data})`,
          type: "error",
          duration: 10000,
        });
        break;
    }

    return;
  } catch (e) {
    console.log(e);
    Vue.$toast.open({
      message: `500 - Une erreur serveur est survenue !`,
      type: "error",
      duration: 4000,
    });
  }
}

export default checkErrors;
