<template>
  <div class="card">
    <div class="card-header"><i class="icon-graph"></i> Marketing Data</div>
    <div class="card-block">
      <dl class="row">
        <dt class="col-sm-4">utm_source</dt>
        <dd class="col-sm-4">
          {{ form.utm_source }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">utm_medium</dt>
        <dd class="col-sm-8">
          {{ form.utm_medium }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">utm_term</dt>
        <dd class="col-sm-8">
          {{ form.utm_term }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">utm_content</dt>
        <dd class="col-sm-8">
          {{ form.utm_content }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">utm_campaign</dt>
        <dd class="col-sm-8">
          {{ form.utm_campaign }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">gclid</dt>
        <dd class="col-sm-8 overflow">
          {{ form.gclid }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">referer</dt>
        <dd class="col-sm-8 overflow">
          {{ form.referer }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">sourceUrl</dt>
        <dd class="col-sm-8 overflow">
          {{ form.sourceUrl }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    marketing: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {},
    };
  },

  created() {
    this.form = this.marketing;
  },
};
</script>

<style>
.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
