var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card sessions-wrapper"},[_c('div',{staticClass:"card-header"},[_c('i',{staticClass:"icon-directions"}),_vm._v(" Séances "),(
        _vm.order.status === 'pending' &&
        _vm.sessions.length != _vm.order.session &&
        _vm.hasPermission(_vm.$store.state.user, 'ORDERS_WRITE')
      )?_c('button',{staticClass:"btn btn-sm btn-warning float-right",attrs:{"type":"button"},on:{"click":function($event){_vm.addAllowed = true}}},[_c('i',{staticClass:"fa fa-unlock"}),_vm._v("  Autoriser des séances temporaires ")]):(
        _vm.order.status === 'partial' &&
        _vm.hasPermission(_vm.$store.state.user, 'ORDERS_WRITE')
      )?_c('button',{staticClass:"btn btn-sm btn-danger float-right",on:{"click":_vm.forcePayed}},[_vm._v(" Forcer le statut \"payée\" ")]):_vm._e()]),(_vm.sessions.length > 0)?_c('div',{staticClass:"card-block"},[_c('Sessions',{attrs:{"sessions-data":_vm.sessions,"live":!!_vm.order.live,"visio":_vm.order.visio && _vm.order.visio.hasVisio && !!_vm.order.visio.roomUuid,"show-nb-of-participants":_vm.order.requiredSessionUserCount},on:{"change-coach":_vm.reload}})],1):_c('div',{staticClass:"card-block"},[_c('small',[_vm._v("La commande n'a pas été payée.")]),(
        !_vm.order.invoice && _vm.hasPermission(_vm.$store.state.user, 'ORDERS_WRITE')
      )?_c('button',{staticClass:"btn btn-sm float-right",on:{"click":_vm.forceInvoice}},[_vm._v(" Créer une facture ")]):_vm._e()]),_c('modal',{attrs:{"title":"Autoriser des séances temporaires","effect":"fade/zoom"},on:{"ok":_vm.handleAddAllowed},model:{value:(_vm.addAllowed),callback:function ($$v) {_vm.addAllowed=$$v},expression:"addAllowed"}},[_c('v-select',{attrs:{"options":_vm.allowedOptions},model:{value:(_vm.allowed),callback:function ($$v) {_vm.allowed=$$v},expression:"allowed"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }