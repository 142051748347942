<template>
  <div class="card">
    <div class="card-header">
      <i class="fa fa-folder"></i> Documents
      <label
        class="switch switch-sm switch-text switch-success float-right mb-0 ml-1"
        v-if="hasPermission($store.state.user, 'USERS_WRITE')"
      >
        <input
          type="checkbox"
          class="switch-input"
          v-model="coach.disbursement"
          @click="handleDisbursement"
        />
        <span class="switch-label" data-on="On" data-off="Off"></span>
        <span class="switch-handle"></span>
      </label>
      <button
        class="btn btn-success btn-sm float-right"
        @click="handleDisbursement(true)"
        v-if="
          !coach.disbursement && hasPermission($store.state.user, 'USERS_WRITE')
        "
      >
        <i class="fa fa-check-circle"></i> Valider
      </button>
    </div>

    <div class="card-block">
      <table class="table table-hover">
        <tr v-for="(d, index) in documents" :key="d.fileId">
          <td>
            <a @click="downloadSecuredFile(`uploads/document/${d.fileId}`)"
              >{{ d.name || d.tag }}
            </a>
            <p class="text-muted">
              {{ d.tag }}
            </p>
          </td>
          <td class="text-right">
            <template v-if="d.validatedAt">
              {{ "Validé le " + formatDate(d.validatedAt) }}
            </template>
            <template v-else>
              <button
                class="btn btn-success"
                @click="validDocument(d)"
                v-if="hasPermission($store.state.user, 'DOCUMENTS_WRITE')"
              >
                <i class="icon-check"></i>
                Valider
              </button>
            </template>
          </td>
          <td class="text-right">
            <button
              class="btn btn-danger btn-sm"
              @click="removeDocument(d.fileId, index)"
              v-if="hasPermission($store.state.user, 'USERS_WRITE')"
            >
              <i class="fa fa-trash" />
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import DownloadFile from "../mixin/DownloadFile";

export default {
  props: {
    coach: {
      type: Object,
      required: true,
    },
  },
  mixins: [DownloadFile],
  data() {
    return {
      documents: [],
    };
  },
  mounted() {
    this.documents = this.coach.documents;
  },
  methods: {
    handleDisbursement(event) {
      if (typeof event === "boolean") {
        this.coach.disbursement = event;
      }

      this.$api.put("/users", {
        email: this.coach.email,
        disbursement:
          typeof event === "boolean" ? event : event.target?.checked,
      });
    },
    validDocument(doc) {
      this.$confirm({
        message: "Valider le document ?",
        onConfirm: async () => {
          await this.$api.patch(`/users/coachs/document/${doc.fileId}`, {
            email: this.coach.email,
          });

          this.documents = this.documents.map((d) => {
            if (d.fileId === doc.fileId) {
              return {
                ...d,
                validatedAt: new Date().toISOString(),
              };
            }

            return d;
          });
        },
      });
    },
    removeDocument(fileId, index) {
      this.$confirm({
        message: "Supprimer le document ?",
        onConfirm: async () => {
          await this.$api.delete(`/users/coachs/document/${fileId}`, {
            data: {
              email: this.coach.email,
            },
          });

          this.documents.splice(index, 1);
        },
      });
    },
  },
};
</script>
