<template>
  <div>
    <el-table :data="sessionsBatches">
      <el-table-column label="" prop="color" align="center" width="30">
        <template slot-scope="scope">
          <ColorChip :color="scope.row.color" />
        </template>
      </el-table-column>
      <el-table-column
        label="Nom"
        prop="label"
        min-width="150"
      ></el-table-column>
      <el-table-column label="Entreprise" prop="company.name" min-width="200">
        <template slot-scope="scope">
          <a href="#" @click.prevent="goToCompany(scope.row.company)">{{
            scope.row.company.name
          }}</a>
        </template>
      </el-table-column>
      <el-table-column label="Créé le" prop="createdAt">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column label="Séances" prop="sessions.length" align="center">
        <template slot-scope="scope">
          {{ scope.row.sessions.length }}
        </template>
      </el-table-column>
      <el-table-column label="Exporter" align="center">
        <template slot-scope="scope">
          <button
            type="button"
            class="btn btn-sm btn-info"
            @click="sessionsBatchExport = scope.row"
          >
            <i class="fa fa-download mr-05"></i> Exporter
          </button>
        </template>
      </el-table-column>
      <el-table-column label="" align="right">
        <template slot-scope="scope">
          <button
            type="button"
            class="btn btn-sm btn-primary mr-05"
            @click="editSessionsBatch(scope.row)"
          >
            <i class="fa fa-edit"></i>
          </button>
          <button
            type="button"
            class="btn btn-sm btn-danger"
            @click="deleteSessionsBatch(scope.row)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </template>
      </el-table-column>
    </el-table>

    <SessionsBatchExportModal
      :sessions-batch="sessionsBatchExport"
      @close="sessionsBatchExport = null"
    />
  </div>
</template>

<script>
import ColorChip from "../ColorChip";
import SessionsBatchExportModal from "./SessionsBatchExportModal";

export default {
  components: {
    ColorChip,
    SessionsBatchExportModal,
  },

  props: {
    sessionsBatches: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      sessionsBatchExport: null,
    };
  },

  methods: {
    goToCompany({ id, isPublicProcurment }) {
      const route = this.$router.resolve({
        name: isPublicProcurment
          ? "public-procurement-details"
          : "company-details",
        params: {
          id,
        },
      });

      window.open(route.href, "_blank");
    },

    editSessionsBatch(sessionsBatch) {
      this.$emit("edit", sessionsBatch);
    },

    deleteSessionsBatch(sessionsBatch) {
      this.$confirm({
        message: `Êtes-vous sûr de vouloir supprimer le lot "${sessionsBatch.label}" lié à l'entreprise "${sessionsBatch.company.name}" ?`,
        onConfirm: async () => {
          try {
            await this.$api.delete(`/sessions-batches/${sessionsBatch.id}`);

            this.$emit("deleted", sessionsBatch);
          } catch (e) {
            this.$errorToast("Impossible de supprimer le lot");
          }
        },
      });
    },
  },
};
</script>
