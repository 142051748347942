<template>
  <div class="animated fadeIn">
    <div class="card">
      <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
      <div class="card-block">
        <div class="row">
          <div class="form-group col-sm-3">
            <label>Date de création</label>
            <datepicker
              v-model="filters.begin"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-sm-3">
            <label>et</label>
            <datepicker
              v-model="filters.end"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="col-md-3 d-flex flex-column">
            <label for="commercial-filter">Commercial</label>
            <v-select
              :options="commercials"
              v-model="filters.commercial"
            ></v-select>
          </div>
          <div class="col-md-3 d-flex flex-column">
            <label for="commercial-filter">Concept</label>
            <v-select :options="concepts" v-model="filters.concept"></v-select>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-4">
            <button
              :disabled="loading"
              type="button"
              class="btn btn-sm btn-secondary"
              @click="loadData"
            >
              <i class="icon-eye"></i>&nbsp; Visualiser
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Datepicker from "vuejs-datepicker";
import DownloadFile from "../mixin/DownloadFile";

export default {
  components: {
    Datepicker,
  },

  mixins: [DownloadFile],

  data() {
    return {
      loading: false,
      stats: {},
      filters: {
        begin: moment().subtract("1", "year").startOf("y").format(),
        end: moment().subtract("1", "year").endOf("y").format(),
        concept: undefined,
        commercial: undefined,
      },
      concepts: [],
      commercials: [],
    };
  },

  async created() {
    await this.fetchCommercials();
    await this.fetchConcepts();
  },

  methods: {
    async handleFilters(filters) {
      this.filters = filters;

      await this.loadData();
    },

    async loadData() {
      this.loading = true;

      try {
        const { begin, end, commercial, concept } = this.filters;
        const { data } = await this.$api.get("/marketings/kpi-export", {
          params: {
            begin,
            end,
            concept: concept?.value || undefined,
            commercial: commercial?.value || undefined,
          },
        });

        this.downloadXLSX(data, "kpi-export");
      } catch (error) {
        console.error(error);

        this.$notify.error(
          "Une erreur est survenue lors de la génération du fichier"
        );
      }

      this.loading = false;
    },

    async fetchCommercials() {
      const { data } = await this.$api.get("/users/commercials/search");

      this.commercials = data.map((c) => ({
        value: c.email,
        label: c.commercial.pseudo,
      }));
    },

    async fetchConcepts() {
      const { data } = await this.$api.get("/concepts");

      this.concepts = data.map((c) => c.name);
    },
  },
};
</script>
