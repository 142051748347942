<template>
  <div class="wrapper">
    <Tabs v-model="activeTab" @input="changeTab" class="empty-tab">
      <Tab
        :key="`tab-${index}`"
        v-for="(item, index) of authorizedTabs"
        :header="item.label"
      >
      </Tab>
    </Tabs>
    <router-view></router-view>
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";

export default {
  components: {
    Tabs,
    Tab,
  },

  data() {
    return {
      activeTab: 0,
      tabs: [
        {
          label: "Feuilles d'émargement",
          name: "signature-sheets-list",
          requiredPermission: [
            "PUBLIC_PROCUREMENTS_READ",
            "COMPANIES_COMPANY_READ",
          ],
        },
        {
          label: "Modèles",
          name: "signature-sheets-templates",
          requiredPermission: [
            "PUBLIC_PROCUREMENTS_READ",
            "COMPANIES_COMPANY_READ",
          ],
        },
        {
          label: "Lots",
          name: "sessions-batches",
          requiredPermission: [
            "PUBLIC_PROCUREMENTS_READ",
            "COMPANIES_COMPANY_READ",
          ],
        },
      ],
    };
  },

  computed: {
    authorizedTabs() {
      return this.tabs.filter((tab) => {
        return Array.isArray(tab.requiredPermission)
          ? tab.requiredPermission.some((permission) =>
              this.hasPermission(this.$store.state.user, permission)
            )
          : this.hasPermission(this.$store.state.user, tab.requiredPermission);
      });
    },
  },

  created() {
    if (
      this.$route.name === "signature-sheets" &&
      this.authorizedTabs?.length > 0
    ) {
      this.$router.push({ name: this.authorizedTabs[0].name });
    }

    this.setCurrentTab(this.$route.name);
  },

  methods: {
    changeTab(index) {
      if (index >= 0) {
        this.$router.push({ name: this.authorizedTabs[index].name });
      }
    },

    setCurrentTab(currentPath) {
      this.activeTab = this.authorizedTabs.findIndex(
        (tab) => tab.name === currentPath || tab.children?.includes(currentPath)
      );
    },
  },

  watch: {
    $route(current) {
      if (
        current.name === "signature-sheets" &&
        this.authorizedTabs?.length > 0
      ) {
        this.$router.push({ name: this.authorizedTabs[0].name });
      }

      this.setCurrentTab(current.name);
    },
  },
};
</script>
