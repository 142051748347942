<template>
  <div class="card">
    <div class="card-header">
      <i class="fa fa-video-camera"></i> Visio
      <label
        v-if="hasPermission($store.state.user, 'ORDERS_WRITE')"
        class="switch switch-sm switch-text switch-info float-right mb-0"
      >
        <input
          type="checkbox"
          class="switch-input"
          v-model="hasVisio"
          @click="handleVisio"
        />
        <span class="switch-label" data-on="On" data-off="Off"></span>
        <span class="switch-handle"></span>
      </label>
      <span v-else class="float-right">{{
        order.attribution ? "Oui" : "Non"
      }}</span>
    </div>
    <div v-if="hasVisio" class="card-body py-2 px-2">
      <span>Type de prestations (visio): </span>
      <span v-for="tag in room.tags" :key="tag.uuid">
        <strong>{{ tag.label }}</strong>, 
      </span>
      <div v-if="room.moderatorKey || room.participantKey" class="mt-2">
        <hr />
        <ul class="list-unstyled">
          <li>
            Administrateur:
            <a :href="room.moderatorKey" target="_blank">{{ room.moderatorKey }}</a>
          </li>
          <li class="mt-1">
            Participant:
            <a :href="room.participantKey" target="_blank">{{
              room.participantKey
            }}</a>
          </li>
        </ul>
      </div>
      <div v-else class="mt-2">
        <p class="text-center">La room n'a pas encore été créé.</p>
      </div>
      <div v-if="roomUuid">
        <hr />
        <div class="mt-2 d-flex justify-content-end">
          <button @click="showDeleteModal = true" class="btn btn-sm btn-danger">
            <i class="fa fa-trash"></i>
            Supprimer la room (irréversible)
          </button>
        </div>
      </div>
    </div>
    <modal
      title="Supprimer la room"
      v-model="showDeleteModal"
      effect="fade/zoom"
    >
      <div slot="modal-body" class="modal-body">
        <div class="alert alert-danger" role="alert">
          Attention ! Supprimer cette room est irréversible.
        </div>
      </div>
      <div
        slot="modal-footer"
        class="modal-footer d-flex justify-content-between"
      >
        <button
          type="button"
          class="btn btn-default mr-1"
          @click="showDeleteModal = false"
        >
          Annuler
        </button>
        <button type="button" class="btn btn-danger" @click="deleteCompany">
          Oui, je confirme
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "vue-strap/src/Modal";

export default {
  components: {
    Modal,
  },

  data() {
    return {
      hasVisio: false,
      roomUuid: null,
      room: {},
      showDeleteModal: false,
    };
  },

  async created() {
    this.hasVisio = this.order?.visio?.hasVisio || false;
    this.roomUuid = this.order?.visio?.roomUuid || null;

    if (!this.order.visio) {
      return;
    }

    const { data } = await this.$api.get(`/visio/${this.roomUuid}`);

    this.room = data;
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  methods: {
    async deleteCompany() {
      try {
        await this.$api.delete(`/visio/${this.roomUuid}`);

        this.roomUuid = null;
      } catch (e) {
        this.$errorToast(
          "Une erreur est survenue lors de la suppression de la room."
        );
      }

      this.showDeleteModal = false;
    },

    async handleVisio(event) {
      await this.$api.put(`/orders/${this.order.id}`, {
        visio: {
          hasVisio: event.target.checked,
        },
      });
    },
  },
};
</script>
