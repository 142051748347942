var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('i',{staticClass:"icon-people"}),_vm._v(" Fiches clients "),(_vm.userHasWritePermission)?_c('button',{staticClass:"btn btn-sm btn-primary float-right",on:{"click":function($event){_vm.addClientModal.show = true}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Ajouter ")]):_vm._e()]),_c('div',{staticClass:"card-block"},[_c('el-table',{attrs:{"data":_vm.companyClients}},[_c('el-table-column',{attrs:{"label":"Nom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('a',{on:{"click":function($event){return _vm.goToClient(scope.row.id)}}},[(scope.row.firstName && scope.row.lastName)?_c('span',[_vm._v(" "+_vm._s(scope.row.firstName)+" "+_vm._s(scope.row.lastName)+" ")]):_c('span',[_vm._v(_vm._s(scope.row.email))])]),_c('br')]}}])}),_c('el-table-column',{attrs:{"label":"Statut","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status)?_c('StatusRel',{attrs:{"status":scope.row.status}}):_vm._e(),_c('br'),(scope.row.status && scope.row.status === 'lost')?_c('span',[_vm._v(" "+_vm._s(_vm.lostReasonsLabel(scope.row.lostReason))+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Facturation","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              _vm.hasPermission(_vm.$store.state.user, 'ACQUISITIONS_CLIENT_WRITE')
            )?_c('el-switch',{attrs:{"value":scope.row.isB2BBilling,"active-text":"Oui","inactive-text":"Non","size":"mini"},on:{"change":function($event){return _vm.toggleBilling(scope.row.email)}}}):_c('span',[_vm._v(" "+_vm._s(scope.row.isB2BBilling ? "Oui" : "Non")+" ")])]}}])}),_c('el-table-column',{attrs:{"align":"right","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.company.referentClientEmail === scope.row.email)?_c('div',{staticClass:"d-flex justify-content-end"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: 'Client référent de l\'entreprise',
                placement: 'top',
              }),expression:"{\n                content: 'Client référent de l\\'entreprise',\n                placement: 'top',\n              }"}],staticClass:"fa fa-star d-block"})]):(_vm.userHasWritePermission)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: 'Supprimer le client de l\'entreprise',
              placement: 'top',
            }),expression:"{\n              content: 'Supprimer le client de l\\'entreprise',\n              placement: 'top',\n            }"}],staticClass:"btn btn-danger btn-sm",on:{"click":function($event){return _vm.unlinkCompany(scope.row.email)}}},[_c('i',{staticClass:"fa fa-chain-broken"})]):_vm._e()]}}])})],1)],1),(_vm.userHasWritePermission)?_c('modal',{attrs:{"title":"Ajouter une fiche client","effect":"fade/zoom"},model:{value:(_vm.addClientModal.show),callback:function ($$v) {_vm.$set(_vm.addClientModal, "show", $$v)},expression:"addClientModal.show"}},[_c('div',{staticClass:"modal-body",attrs:{"slot":"modal-body"},slot:"modal-body"},[_c('multiselect',{attrs:{"options":_vm.clientsOptions,"multiple":false,"allow-empty":false,"label":"label","track-by":"label","searchable":true,"loading":_vm.clientsIsLoading,"options-limit":10,"max-height":600,"hide-selected":true},on:{"search-change":_vm.searchClients},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_vm._v("Rechercher un client")]},proxy:true},{key:"noResult",fn:function(){return [_vm._v("Aucun client trouvé")]},proxy:true}],null,false,2940060275),model:{value:(_vm.addClientModal.form.client),callback:function ($$v) {_vm.$set(_vm.addClientModal.form, "client", $$v)},expression:"addClientModal.form.client"}})],1),_c('div',{staticClass:"modal-footer d-flex justify-content-between",attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('button',{staticClass:"btn btn-default mr-1",attrs:{"type":"button"},on:{"click":function($event){_vm.addClientModal.show = false}}},[_vm._v(" Annuler ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.linkClientCompany()}}},[_vm._v(" Créer ")])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }