<template>
  <modal
    title="Création d'une nouvelle entrée"
    v-model="visible"
    okText="Créer"
    cancelText="Annuler"
    effect="fade/zoom"
    large
    @cancel="closeModal"
    @ok="create"
  >
    <div>
      <dl class="row">
        <dt class="col-sm-4">utm_source</dt>
        <dd class="col-sm-4">
          <el-input v-model="form.utm_source" size="mini" />
        </dd>
        <dd class="col-sm-4">
          <v-select
            v-if="!form.utm_source"
            :options="['Appel entrant', 'Parrainage', 'Contact mail']"
            v-model="form.utm_source"
            size="mini"
          ></v-select>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">utm_medium</dt>
        <dd class="col-sm-8">
          <el-input v-model="form.utm_medium" size="mini" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">utm_term</dt>
        <dd class="col-sm-8">
          <el-input v-model="form.utm_term" size="mini" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">utm_content</dt>
        <dd class="col-sm-8">
          <el-input v-model="form.utm_content" size="mini" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">utm_campaign</dt>
        <dd class="col-sm-8">
          <el-input v-model="form.utm_campaign" size="mini" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">gclid</dt>
        <dd class="col-sm-8 overflow">
          <el-input v-model="form.gclid" size="mini" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">referer</dt>
        <dd class="col-sm-8 overflow">
          <el-input v-model="form.referer" size="mini" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">sourceUrl</dt>
        <dd class="col-sm-8 overflow">
          <el-input v-model="form.sourceUrl" size="mini" />
        </dd>
      </dl>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";

export default {
  components: {
    Modal,
  },

  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      visible: false,
      form: {},
    };
  },

  watch: {
    openModal(val) {
      this.visible = val;
    },
  },

  methods: {
    closeModal() {
      this.$emit("close");
    },

    create() {
      for (const key in this.form) {
        if (this.form[key] && this.form[key].trim().length === 0) {
          delete this.form[key];
        }
      }

      if (Object.keys(this.form).length === 0) {
        return this.$toast.error("Veuillez remplir minimum un champs");
      }

      this.$emit("create", this.form);

      this.form = {};
      this.closeModal();
    },
  },
};
</script>
