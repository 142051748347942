<template>
  <div class="wrapper">
    <router-view></router-view>
    <Loader :is-visible="loading" />

    <div
      class="animated fadeIn"
      v-if="['signature-sheets-list'].indexOf($route.name) > -1"
    >
      <SignatureSheetsFilters @apply="applyFilters" />

      <div class="card">
        <div class="card-header">
          <i class="fa fa-file-text-o"></i> Feuilles d'émargement ({{ total }})
        </div>

        <div class="card-block">
          <SignatureSheetsTable :signature-sheets="signatureSheets" />

          <paginate
            ref="paginate"
            v-model="currentPage"
            :page-count="pageCount"
            :click-handler="changePage"
            prev-text="Précédent"
            next-text="Suivant"
            container-class="pagination"
            class="mt-1"
          ></paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "../components/Loader";
import Paginate from "vuejs-paginate";
import SignatureSheetsFilters from "../components/company/SignatureSheetsFilters";
import SignatureSheetsTable from "../components/company/SignatureSheetsTable";

export default {
  components: {
    Loader,
    Paginate,
    SignatureSheetsFilters,
    SignatureSheetsTable,
  },

  data() {
    return {
      loading: false,
      signatureSheets: [],
      total: 0,
      currentPage: 1,
      limit: 20,
      filters: {
        createdAtBegin: null,
        createdAtEnd: null,
        updatedAtBegin: null,
        updatedAtEnd: null,
        scheduledAtBegin: null,
        scheduledAtEnd: null,
        submittedAtBegin: null,
        submittedAtEnd: null,
        companyId: null,
        sessionsBatchId: null,
        submittedByCoachEmail: null,
        status: ["pending"],
      },
    };
  },

  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },

    pageCount() {
      if (!this.total) {
        return 0;
      }

      return Math.ceil(this.total / this.limit);
    },
  },

  watch: {
    currentPage() {
      this.$refs.paginate.selected = this.currentPage - 1;
    },
  },

  mounted() {
    this.getSignatureSheets();
  },

  methods: {
    async getSignatureSheets() {
      try {
        this.loading = true;

        const { data } = await this.$api.get("/signature-sheets", {
          params: {
            offset: this.offset,
            limit: this.limit,
            ...this.filters,
          },
        });

        this.total = data.total;
        this.signatureSheets = data.signatureSheets;
      } catch (e) {
        this.signatureSheets = [];
      } finally {
        this.loading = false;
      }
    },

    changePage(page) {
      this.currentPage = page;

      this.getSignatureSheets();
    },

    applyFilters(filters) {
      this.filters = { ...this.filters, ...filters };

      this.changePage(1);
    },
  },
};
</script>
